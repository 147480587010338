@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-Regular.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}

@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-Medium.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}


@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-SemiBold.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}

@font-face {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-SemiBoldItalic.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}

@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-Bold.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}

@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/dm-sans/DMSans-ExtraBold.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}


@font-face {
    font-family: 'Antonio';
    font-style: normal;
    font-weight: 700;
    font-display: swap; /* or block, auto, fallback, optional */
    src: local('DMSans'),
         url("../fonts/antonio/Antonio-Bold.woff2") format("woff2");
    unicode-range: U+0020-024F; /* Latin glyphs */
}

@font-face {
    font-family: "iconfont";
    src: url("../fonts/iconfont/iconfont.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
