/* ==========================================================================

                        M I N I C A R T

========================================================================== */

@import '../product/originalAndLowestPrice';
@import '../components/cartProgressBar';

.cc-minicart-overlay {
    @include animationSlow();

    background: $white;
    cursor: default;
    display: block;
    position: fixed;
    right: 0;
    top: auto;
    bottom: 200%;
    width: 100%;
    z-index: 40;

    @include media-breakpoint-up(lg) {
        display: none;
        top: 100%;
        width: rem-calc(369);
    }

    &.show {
        bottom: 0;

        &:before {
            content: '';
            background: rgba($black, 0.2);
            backdrop-filter: blur(#{rem-calc(5)});
            display: block;
            height: 100%;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 39;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            display: block;
            right: rem-calc(24);
            position: absolute;
            bottom: auto;
            top: 100%;
            border: 1px solid $black;
            border-radius: rem-calc(2);
        }
    }

    .cc-content-minicart {
        background-color: $white;
        font-size: rem-calc(14);
        overflow-y: auto;
        position: relative;
        text-align: left;
        z-index: 40;
        border-radius: rem-calc(8) rem-calc(8) 0 0;

        &-header {
            min-height: rem-calc(48);
            position: relative;

            .cc-content-close {
                position: absolute;
                right: rem-calc(16);
                top: rem-calc(16);

                @include media-breakpoint-down(xs) {
                    top: rem-calc(20);
                    right: rem-calc(18);
                }

                .cc-close-button {
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    display: block;
                    margin: 0;
                    padding: 0;

                    .cc-icon {
                        color: $black;
                        font-size: rem-calc(18);

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }

            &-closeIcon {
                width: rem-calc(14);
                height: rem-calc(14);

                &:hover {
                    color: $red;
                }
            }
        }

        &-container {
            padding: rem-calc(0) rem-calc(16) rem-calc(16);
        }

        .cc-content-minicart-items {
            margin: 0;
        }

        .cc-content-cart-item {
            padding: 0 0 rem-calc(24);

            .cc-cart-item {
                position: relative;

                .cc-prod-row {
                    .cc-content-image {
                        margin-right: rem-calc(8);

                        @include media-breakpoint-up(lg) {
                            margin-right: rem-calc(9);
                        }

                        .cc-image-link {
                            display: block;
                            margin: 0;
                            padding: 0;
                            width: rem-calc(87);

                            .cc-image {
                                display: block;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    .cc-content-attributes {
                        @include flexGenerator(column, flex-start, stretch, 0);
                        flex: 1;

                        .cc-prod-name {
                            color: $black;
                            display: block;
                            font-weight: $primary-font-medium;
                            font-size: rem-calc(13);
                            line-height: rem-calc(18);
                            letter-spacing: rem-calc(0.13);
                            margin: 0;
                            margin-bottom: rem-calc(4);
                            padding: 0;
                            text-decoration: none;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .cc-attribute {
                            display: block;
                            font-size: 0;
                            margin-top: rem-calc(4);
                            text-align: left;

                            &:last-child {
                                @include media-breakpoint-up(lg) {
                                    margin-top: 0;
                                }
                            }

                            .cc-attribute-label,
                            .cc-attribute-value {
                                color: $warm-grey-05;
                                display: inline-block;
                                font-weight: $primary-font-regular;
                                font-size: rem-calc(13);
                                line-height: rem-calc(18);
                                letter-spacing: rem-calc(0.13);
                                margin: 0;
                                padding: 0;
                                vertical-align: middle;

                                @include media-breakpoint-up(lg) {
                                    font-size: rem-calc(11);
                                    line-height: rem-calc(16);
                                    letter-spacing: rem-calc(0.22);
                                }

                                &.cc-original-price {
                                    text-decoration: line-through;
                                }
                            }

                            &.cc-attribute-availability {
                                .cc-attribute-value {
                                    color: $green1;

                                    &.cc-attribute-value-lowstock-nostock {
                                        color: $red1;
                                    }

                                    &.cc-attribute-value-instock-date {
                                        color: $red1;
                                    }
                                }
                            }

                            @include original-and-pp-price-container(12, 12, 18, 4, flex-start);
                        }
                    }
                }

                .cc-content-price {
                    .cc-price {
                        color: $black;
                        display: block;
                        font-weight: $primary-font-bold;
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        margin: 0 0 rem-calc(4);
                        padding: 0;

                        &.cc-sales-reduced {
                            color: $red1;
                        }
                    }

                    .cc-gst-discount {
                        color: $red1;
                        font-family: $primary-font;
                        font-weight: $primary-font-regular;
                        font-size: rem-calc(13);
                        line-height: rem-calc(20);
                    }
                }

                &-flex {
                    @include flexGenerator(column);
                    max-width: calc(100% - #{rem-calc(95)});

                    @include media-breakpoint-up(sm) {
                        flex-direction: row;
                    }

                    @include media-breakpoint-up(lg) {
                        flex-direction: column;
                        max-width: calc(100% - #{rem-calc(99)});
                    }
                }
            }
        }

        &-buttons {
            @include flexGenerator(column, flex-start, stretch);
            flex-wrap: wrap;
            gap: rem-calc(16);
            overflow: hidden;

            .cc-content-button {
                flex-grow: 1;
                margin: 0;
                text-align: center;

                &:not(.cc-content-button-main) {
                    @include media-breakpoint-up(sm) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .cc-link-type-1 {
                    font-weight: $primary-font-medium;
                    padding: 0;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(1.2);
                    text-transform: uppercase;
                    border-bottom: rem-calc(1) solid $black;

                    &:hover {
                        border-color: $red;
                    }
                }
            }
        }

        .cc-minicart-title {
            color: $black;
            display: block;
            text-align: left;
            font-size: rem-calc(25);
            font-weight: $primary-font-semibold;
            padding-bottom: rem-calc(25);
            padding-right: rem-calc(30);
            text-transform: uppercase;

            @include media-breakpoint-down(xs) {
                font-size: rem-calc(20);
                padding-bottom: rem-calc(20);
            }

            .cc-number {
                color: $grey1;
                display: inline;
                font-weight: $primary-font-medium;
                font-size: rem-calc(20);
                vertical-align: text-bottom;
            }
        }

        .cc-content-error-message {
            .cc-error-message:not(:empty) {
                border: 1px solid $red1;
                color: $red1;
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(16);
                margin-bottom: rem-calc(25);
                padding: rem-calc(18) rem-calc(20);
                text-align: center;
            }
        }
        .cc-content-subtotal {
            overflow: hidden;
            padding-bottom: rem-calc(10);

            .cc-subtotal-label {
                color: $black;
                display: block;
                float: left;
                font-weight: $primary-font-medium;
                font-size: rem-calc(20);
                text-align: left;
                text-transform: uppercase;
                width: 50%;

                @include media-breakpoint-down(xs) {
                    font-size: rem-calc(15);
                    width: 60%;
                }
            }

            .cc-subtotal-value {
                color: $black;
                display: block;
                float: right;
                font-weight: $primary-font-medium;
                font-size: rem-calc(20);
                font-style: italic;
                text-align: right;
                width: 50%;

                @include media-breakpoint-down(xs) {
                    font-size: rem-calc(15);
                    width: 40%;
                }
            }
        }
    }
}

.cc-availability-minicart {
    display: none;
}

.cc-attribute-availability {
    max-width: 70%;
    width: 70%;
}
