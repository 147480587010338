@import "_settings/globalSetting";
@import 'lib/iconfont/iconfont-build';

#viewNewsletterModal {
    .modal-dialog {
        max-width: rem-calc(740);
    }

    .modal-body {
        padding: 0;
    }

    .modal-header {
        position: absolute;
        z-index: 1;
        width: 100%;
        border: 0;
    }

    .cc-container-small {
        padding: 0;
    }
}

.cc-newsletter-popup {

    .cc-newsletter-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .cc-newsletter-col-asset {
            flex: 0 0 37%;
            margin-right: auto;
            max-width: 37%;
            width: 37%;
            align-self: stretch;

            @include media-breakpoint-down(md) {
                flex: 0 0 41%;
                max-width: 41%;
                width: 41%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
            }

            .cc-content-img {
                position: relative;
                height: 100%;
                overflow: hidden;

                .cc-img {
                    object-position: center;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .cc-newsletter-col-main {
            flex: 1;
            max-width: 100%;
            padding: rem-calc(30) rem-calc(10) rem-calc(0);
            width: 100%;

            @include media-breakpoint-up(md) {
                padding: rem-calc(60) rem-calc(40) rem-calc(40) rem-calc(40);
            }
        }
    }

    .cc-content-newsletter-head {
        &-small {
            margin: 0 auto;
            max-width: rem-calc(620);
        }

        .cc-newsletter-title {
            color: $black;
            display: block;
            font-size: rem-calc(25);
            font-weight: $primary-font-semibold;
            line-height: rem-calc(30);
            margin: 0;
            padding-bottom: rem-calc(15);
            text-align: center;
        }


        .cc-newsletter-intro-text {
            color: $black;
            display: block;
            font-size: rem-calc(20);
            font-style: italic;
            font-weight: $primary-font-regular;
            line-height: rem-calc(28);
            padding-bottom: rem-calc(40);
            text-align: center;

            @include media-breakpoint-down(sm) {
                padding-bottom: rem-calc(30);
            }

            @include media-breakpoint-up(lg) {
                margin: rem-calc(0);
            }
        }
    }

    .cc-content-newsletter-form {
        margin: 0 auto;

        .cc-error {
            background: transparent;
            border: rem-calc(1) solid $danger;
            border-radius: 0;
            color: $danger;
            font-family: $primary-font;
            font-size: rem-calc(12);
            font-weight: $primary-font-medium;
            margin-bottom: rem-calc(30);
            padding: rem-calc(15) rem-calc(10);
            text-align: center;
        }
    }


    .cc-newsletter-form {
        .cc-form-group {
            padding-bottom: rem-calc(25);

            &.cc-form-group-button,
            &.cc-form-group-no-label {
                padding-top: rem-calc(24);

                @include media-breakpoint-down(sm) {
                    margin-top: rem-calc(-10);
                    padding-top: 0;
                }
            }

            &.cc-form-group-button {
                @include media-breakpoint-down(sm) {
                    margin-top: rem-calc(-15);
                }
            }

            .is-valid {
                position: relative;

                &::after {
                    [lang='pl'] & {
                        align-items: center;
                        bottom: rem-calc(-8);
                        content: $icon-check-mark;
                        display: flex;
                        font-family: iconfont;
                        font-size: rem-calc(16);
                        height: rem-calc(45);
                        position: absolute;
                        right: rem-calc(16);
                        top: 0;
                    }
                }
            }

            .cc-input,
            .cc-content-select .cc-select {
                margin-bottom: 0;
            }

            .cc-error-message {
                margin-bottom: 0;
                margin-top: rem-calc(5);
            }

            .cc-warning-feedback {
                color: $orange1;
                font-size: rem-calc(12);
                margin-top: rem-calc(8);
            }

            .cc-content-radio {
                margin-bottom: 0;

                .cc-radio-label {
                    margin-right: rem-calc(30);
                }
            }

            .cc-label-description {
                color: $black;
                display: block;
                font-size: rem-calc(14);
                font-weight: $primary-font-regular;
                line-height: rem-calc(21);
                text-align: left;

                a {
                    display: inline;
                    font-weight: $primary-font-semibold;
                    text-decoration: underline;
                }
            }
        }

        .cc-content-button {
            &.cc-content-button-main {
                margin-top: 0;
            }
        }

        .cc-newsletter-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 rem-calc(-15);

            picture,
            img {
                width: 100%;
            }

            .cc-newsletter-col {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 rem-calc(15);
                width: 50%;

                @include media-breakpoint-down(xs) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .cc-newsletter-asset-details {
        margin-bottom: rem-calc(25);

        .cc-interest-text {
            color: $black;
            display: block;
            font-size: rem-calc(15);
            font-style: italic;
            font-weight: $primary-font-semibold;
            line-height: rem-calc(22);
            margin: 0;
            padding: 0 0 rem-calc(25);
            text-align: left;
            text-transform: uppercase;
        }

        .cc-note-text {
            color: $black;
            display: block;
            font-size: rem-calc(13);
            font-weight: $primary-font-regular;
            line-height: rem-calc(21);
            margin: 0;
            padding: 0;
            text-align: left;

            .cc-text-title {
                display: block;
                font-weight: $primary-font-semibold;
                padding-bottom: rem-calc(3);
                text-transform: uppercase;
            }

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .cc-newsletter-asset-categories {
        .cc-content-newsletter-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 auto;
            max-width: rem-calc(440);

            .cc-content-newsletter-button {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 rem-calc(15) rem-calc(30);
                width: 50%;

                @include media-breakpoint-down(xs) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                }

                .cc-newsletter-button {
                    background: transparent;
                    border: rem-calc(1) solid $black;
                    color: $black;
                    cursor: pointer;
                    display: block;
                    font-size: rem-calc(14);
                    font-weight: $primary-font-medium;
                    line-height: rem-calc(18);
                    padding: rem-calc(14);
                    text-align: center;
                    text-transform: uppercase;
                    transition: all .2s ease-in-out;
                    width: 100%;

                    @include media-breakpoint-down(xs) {
                        margin: 0 auto;
                        max-width: rem-calc(190);
                    }

                    &:hover,
                    &.active {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
    }

    .cc-newsletter-links {
        margin-top: rem-calc(15);

        .cc-content-link {
            font-size: 0;
            margin-bottom: rem-calc(20);
            text-align: center;

            a {
                color: $black;
                display: inline-block;
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(18);
                text-decoration: underline;
                text-transform: uppercase;
            }
        }
    }
}
