@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts" !default;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;

$border-radius: 0.1875rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;
// Font sizes
$base16-14px: 0.875em !default;
// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
$svg-icon-caret-right: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 13 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.426.44l11.33 10.515L.426 22.424' fill='none' stroke='currentcolor' stroke-width='1.2'/%3E%3C/svg%3E");