/*
---
name: Iconfont
category: library/iconfont
tag:
    - all
    - icons
---
List of all icons used in project.
<table class="icons-table">
    <thead>
        <tr>
            <td>Icon</td>
            <td>Mixin to include</td>
            <td>Css class</td>
        </tr>
    </thead>
    <tbody>
    
        <tr>
            <td><i class="icon-account"></i></td>
            <td>@include icon(account)</td>
            <td>.icon-account</td>
        </tr>
    
        <tr>
            <td><i class="icon-annivarsary-empty"></i></td>
            <td>@include icon(annivarsary-empty)</td>
            <td>.icon-annivarsary-empty</td>
        </tr>
    
        <tr>
            <td><i class="icon-annivarsary"></i></td>
            <td>@include icon(annivarsary)</td>
            <td>.icon-annivarsary</td>
        </tr>
    
        <tr>
            <td><i class="icon-apek"></i></td>
            <td>@include icon(apek)</td>
            <td>.icon-apek</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-down-big"></i></td>
            <td>@include icon(arrow-down-big)</td>
            <td>.icon-arrow-down-big</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-down"></i></td>
            <td>@include icon(arrow-down)</td>
            <td>.icon-arrow-down</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-left-big"></i></td>
            <td>@include icon(arrow-left-big)</td>
            <td>.icon-arrow-left-big</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-left"></i></td>
            <td>@include icon(arrow-left)</td>
            <td>.icon-arrow-left</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-right-big"></i></td>
            <td>@include icon(arrow-right-big)</td>
            <td>.icon-arrow-right-big</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-right"></i></td>
            <td>@include icon(arrow-right)</td>
            <td>.icon-arrow-right</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-up-big"></i></td>
            <td>@include icon(arrow-up-big)</td>
            <td>.icon-arrow-up-big</td>
        </tr>
    
        <tr>
            <td><i class="icon-arrow-up"></i></td>
            <td>@include icon(arrow-up)</td>
            <td>.icon-arrow-up</td>
        </tr>
    
        <tr>
            <td><i class="icon-bag"></i></td>
            <td>@include icon(bag)</td>
            <td>.icon-bag</td>
        </tr>
    
        <tr>
            <td><i class="icon-bata-club-price-cz"></i></td>
            <td>@include icon(bata-club-price-cz)</td>
            <td>.icon-bata-club-price-cz</td>
        </tr>
    
        <tr>
            <td><i class="icon-bata-logo-cs"></i></td>
            <td>@include icon(bata-logo-cs)</td>
            <td>.icon-bata-logo-cs</td>
        </tr>
    
        <tr>
            <td><i class="icon-bata-logo-en"></i></td>
            <td>@include icon(bata-logo-en)</td>
            <td>.icon-bata-logo-en</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-bata-club"></i></td>
            <td>@include icon(benefit-bata-club)</td>
            <td>.icon-benefit-bata-club</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-day-night"></i></td>
            <td>@include icon(benefit-day-night)</td>
            <td>.icon-benefit-day-night</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-exchange"></i></td>
            <td>@include icon(benefit-exchange)</td>
            <td>.icon-benefit-exchange</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-free-delivery"></i></td>
            <td>@include icon(benefit-free-delivery)</td>
            <td>.icon-benefit-free-delivery</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-online"></i></td>
            <td>@include icon(benefit-online)</td>
            <td>.icon-benefit-online</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-pickup-in-store"></i></td>
            <td>@include icon(benefit-pickup-in-store)</td>
            <td>.icon-benefit-pickup-in-store</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-return-less-cs"></i></td>
            <td>@include icon(benefit-return-less-cs)</td>
            <td>.icon-benefit-return-less-cs</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-return-less"></i></td>
            <td>@include icon(benefit-return-less)</td>
            <td>.icon-benefit-return-less</td>
        </tr>
    
        <tr>
            <td><i class="icon-benefit-return"></i></td>
            <td>@include icon(benefit-return)</td>
            <td>.icon-benefit-return</td>
        </tr>
    
        <tr>
            <td><i class="icon-birthday"></i></td>
            <td>@include icon(birthday)</td>
            <td>.icon-birthday</td>
        </tr>
    
        <tr>
            <td><i class="icon-bubble-ok"></i></td>
            <td>@include icon(bubble-ok)</td>
            <td>.icon-bubble-ok</td>
        </tr>
    
        <tr>
            <td><i class="icon-bubble-warning"></i></td>
            <td>@include icon(bubble-warning)</td>
            <td>.icon-bubble-warning</td>
        </tr>
    
        <tr>
            <td><i class="icon-check-mark"></i></td>
            <td>@include icon(check-mark)</td>
            <td>.icon-check-mark</td>
        </tr>
    
        <tr>
            <td><i class="icon-close-remove"></i></td>
            <td>@include icon(close-remove)</td>
            <td>.icon-close-remove</td>
        </tr>
    
        <tr>
            <td><i class="icon-coated-leather"></i></td>
            <td>@include icon(coated-leather)</td>
            <td>.icon-coated-leather</td>
        </tr>
    
        <tr>
            <td><i class="icon-comb-leather-other"></i></td>
            <td>@include icon(comb-leather-other)</td>
            <td>.icon-comb-leather-other</td>
        </tr>
    
        <tr>
            <td><i class="icon-comb-leather-textile"></i></td>
            <td>@include icon(comb-leather-textile)</td>
            <td>.icon-comb-leather-textile</td>
        </tr>
    
        <tr>
            <td><i class="icon-comb-other-textile"></i></td>
            <td>@include icon(comb-other-textile)</td>
            <td>.icon-comb-other-textile</td>
        </tr>
    
        <tr>
            <td><i class="icon-czech-currency"></i></td>
            <td>@include icon(czech-currency)</td>
            <td>.icon-czech-currency</td>
        </tr>
    
        <tr>
            <td><i class="icon-dinersclub"></i></td>
            <td>@include icon(dinersclub)</td>
            <td>.icon-dinersclub</td>
        </tr>
    
        <tr>
            <td><i class="icon-euro-currency"></i></td>
            <td>@include icon(euro-currency)</td>
            <td>.icon-euro-currency</td>
        </tr>
    
        <tr>
            <td><i class="icon-facebook-letter"></i></td>
            <td>@include icon(facebook-letter)</td>
            <td>.icon-facebook-letter</td>
        </tr>
    
        <tr>
            <td><i class="icon-facebook"></i></td>
            <td>@include icon(facebook)</td>
            <td>.icon-facebook</td>
        </tr>
    
        <tr>
            <td><i class="icon-functional-arrow-left"></i></td>
            <td>@include icon(functional-arrow-left)</td>
            <td>.icon-functional-arrow-left</td>
        </tr>
    
        <tr>
            <td><i class="icon-functional-arrow-right"></i></td>
            <td>@include icon(functional-arrow-right)</td>
            <td>.icon-functional-arrow-right</td>
        </tr>
    
        <tr>
            <td><i class="icon-google-pay"></i></td>
            <td>@include icon(google-pay)</td>
            <td>.icon-google-pay</td>
        </tr>
    
        <tr>
            <td><i class="icon-google-plus"></i></td>
            <td>@include icon(google-plus)</td>
            <td>.icon-google-plus</td>
        </tr>
    
        <tr>
            <td><i class="icon-heart-empty"></i></td>
            <td>@include icon(heart-empty)</td>
            <td>.icon-heart-empty</td>
        </tr>
    
        <tr>
            <td><i class="icon-heart"></i></td>
            <td>@include icon(heart)</td>
            <td>.icon-heart</td>
        </tr>
    
        <tr>
            <td><i class="icon-instagram"></i></td>
            <td>@include icon(instagram)</td>
            <td>.icon-instagram</td>
        </tr>
    
        <tr>
            <td><i class="icon-leather"></i></td>
            <td>@include icon(leather)</td>
            <td>.icon-leather</td>
        </tr>
    
        <tr>
            <td><i class="icon-linkedin"></i></td>
            <td>@include icon(linkedin)</td>
            <td>.icon-linkedin</td>
        </tr>
    
        <tr>
            <td><i class="icon-locator-small"></i></td>
            <td>@include icon(locator-small)</td>
            <td>.icon-locator-small</td>
        </tr>
    
        <tr>
            <td><i class="icon-locator"></i></td>
            <td>@include icon(locator)</td>
            <td>.icon-locator</td>
        </tr>
    
        <tr>
            <td><i class="icon-maestro"></i></td>
            <td>@include icon(maestro)</td>
            <td>.icon-maestro</td>
        </tr>
    
        <tr>
            <td><i class="icon-map-world-active"></i></td>
            <td>@include icon(map-world-active)</td>
            <td>.icon-map-world-active</td>
        </tr>
    
        <tr>
            <td><i class="icon-map-world"></i></td>
            <td>@include icon(map-world)</td>
            <td>.icon-map-world</td>
        </tr>
    
        <tr>
            <td><i class="icon-mastercard"></i></td>
            <td>@include icon(mastercard)</td>
            <td>.icon-mastercard</td>
        </tr>
    
        <tr>
            <td><i class="icon-maximize"></i></td>
            <td>@include icon(maximize)</td>
            <td>.icon-maximize</td>
        </tr>
    
        <tr>
            <td><i class="icon-menu-mobile"></i></td>
            <td>@include icon(menu-mobile)</td>
            <td>.icon-menu-mobile</td>
        </tr>
    
        <tr>
            <td><i class="icon-menu-opened"></i></td>
            <td>@include icon(menu-opened)</td>
            <td>.icon-menu-opened</td>
        </tr>
    
        <tr>
            <td><i class="icon-menu-tablet"></i></td>
            <td>@include icon(menu-tablet)</td>
            <td>.icon-menu-tablet</td>
        </tr>
    
        <tr>
            <td><i class="icon-other"></i></td>
            <td>@include icon(other)</td>
            <td>.icon-other</td>
        </tr>
    
        <tr>
            <td><i class="icon-outersole"></i></td>
            <td>@include icon(outersole)</td>
            <td>.icon-outersole</td>
        </tr>
    
        <tr>
            <td><i class="icon-paypal"></i></td>
            <td>@include icon(paypal)</td>
            <td>.icon-paypal</td>
        </tr>
    
        <tr>
            <td><i class="icon-percentage"></i></td>
            <td>@include icon(percentage)</td>
            <td>.icon-percentage</td>
        </tr>
    
        <tr>
            <td><i class="icon-phone"></i></td>
            <td>@include icon(phone)</td>
            <td>.icon-phone</td>
        </tr>
    
        <tr>
            <td><i class="icon-pinterest"></i></td>
            <td>@include icon(pinterest)</td>
            <td>.icon-pinterest</td>
        </tr>
    
        <tr>
            <td><i class="icon-points-invitation"></i></td>
            <td>@include icon(points-invitation)</td>
            <td>.icon-points-invitation</td>
        </tr>
    
        <tr>
            <td><i class="icon-profile-completed"></i></td>
            <td>@include icon(profile-completed)</td>
            <td>.icon-profile-completed</td>
        </tr>
    
        <tr>
            <td><i class="icon-radio"></i></td>
            <td>@include icon(radio)</td>
            <td>.icon-radio</td>
        </tr>
    
        <tr>
            <td><i class="icon-scalapay"></i></td>
            <td>@include icon(scalapay)</td>
            <td>.icon-scalapay</td>
        </tr>
    
        <tr>
            <td><i class="icon-search"></i></td>
            <td>@include icon(search)</td>
            <td>.icon-search</td>
        </tr>
    
        <tr>
            <td><i class="icon-subcategories"></i></td>
            <td>@include icon(subcategories)</td>
            <td>.icon-subcategories</td>
        </tr>
    
        <tr>
            <td><i class="icon-textile"></i></td>
            <td>@include icon(textile)</td>
            <td>.icon-textile</td>
        </tr>
    
        <tr>
            <td><i class="icon-tiktok"></i></td>
            <td>@include icon(tiktok)</td>
            <td>.icon-tiktok</td>
        </tr>
    
        <tr>
            <td><i class="icon-track-green"></i></td>
            <td>@include icon(track-green)</td>
            <td>.icon-track-green</td>
        </tr>
    
        <tr>
            <td><i class="icon-track"></i></td>
            <td>@include icon(track)</td>
            <td>.icon-track</td>
        </tr>
    
        <tr>
            <td><i class="icon-twitter-pure"></i></td>
            <td>@include icon(twitter-pure)</td>
            <td>.icon-twitter-pure</td>
        </tr>
    
        <tr>
            <td><i class="icon-twitter"></i></td>
            <td>@include icon(twitter)</td>
            <td>.icon-twitter</td>
        </tr>
    
        <tr>
            <td><i class="icon-upper"></i></td>
            <td>@include icon(upper)</td>
            <td>.icon-upper</td>
        </tr>
    
        <tr>
            <td><i class="icon-visa"></i></td>
            <td>@include icon(visa)</td>
            <td>.icon-visa</td>
        </tr>
    
        <tr>
            <td><i class="icon-wishlist-added"></i></td>
            <td>@include icon(wishlist-added)</td>
            <td>.icon-wishlist-added</td>
        </tr>
    
        <tr>
            <td><i class="icon-wishlist"></i></td>
            <td>@include icon(wishlist)</td>
            <td>.icon-wishlist</td>
        </tr>
    
        <tr>
            <td><i class="icon-youtube-ce"></i></td>
            <td>@include icon(youtube-ce)</td>
            <td>.icon-youtube-ce</td>
        </tr>
    
        <tr>
            <td><i class="icon-youtube"></i></td>
            <td>@include icon(youtube)</td>
            <td>.icon-youtube</td>
        </tr>
    
        <tr>
            <td><i class="icon-zoom"></i></td>
            <td>@include icon(zoom)</td>
            <td>.icon-zoom</td>
        </tr>
    
    </tbody>
</table>
*/

@mixin icon-styles {
    display: block;
    font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";
    
    @if $filename == account {
        $char: "\E001";
    }
    @if $filename == annivarsary-empty {
        $char: "\E002";
    }
    @if $filename == annivarsary {
        $char: "\E003";
    }
    @if $filename == apek {
        $char: "\E004";
    }
    @if $filename == arrow-down-big {
        $char: "\E005";
    }
    @if $filename == arrow-down {
        $char: "\E006";
    }
    @if $filename == arrow-left-big {
        $char: "\E007";
    }
    @if $filename == arrow-left {
        $char: "\E008";
    }
    @if $filename == arrow-right-big {
        $char: "\E009";
    }
    @if $filename == arrow-right {
        $char: "\E00A";
    }
    @if $filename == arrow-up-big {
        $char: "\E00B";
    }
    @if $filename == arrow-up {
        $char: "\E00C";
    }
    @if $filename == bag {
        $char: "\E00D";
    }
    @if $filename == bata-club-price-cz {
        $char: "\E00E";
    }
    @if $filename == bata-logo-cs {
        $char: "\E00F";
    }
    @if $filename == bata-logo-en {
        $char: "\E010";
    }
    @if $filename == benefit-bata-club {
        $char: "\E011";
    }
    @if $filename == benefit-day-night {
        $char: "\E012";
    }
    @if $filename == benefit-exchange {
        $char: "\E013";
    }
    @if $filename == benefit-free-delivery {
        $char: "\E014";
    }
    @if $filename == benefit-online {
        $char: "\E015";
    }
    @if $filename == benefit-pickup-in-store {
        $char: "\E016";
    }
    @if $filename == benefit-return-less-cs {
        $char: "\E017";
    }
    @if $filename == benefit-return-less {
        $char: "\E018";
    }
    @if $filename == benefit-return {
        $char: "\E019";
    }
    @if $filename == birthday {
        $char: "\E01A";
    }
    @if $filename == bubble-ok {
        $char: "\E01B";
    }
    @if $filename == bubble-warning {
        $char: "\E01C";
    }
    @if $filename == check-mark {
        $char: "\E01D";
    }
    @if $filename == close-remove {
        $char: "\E01E";
    }
    @if $filename == coated-leather {
        $char: "\E01F";
    }
    @if $filename == comb-leather-other {
        $char: "\E020";
    }
    @if $filename == comb-leather-textile {
        $char: "\E021";
    }
    @if $filename == comb-other-textile {
        $char: "\E022";
    }
    @if $filename == czech-currency {
        $char: "\E023";
    }
    @if $filename == dinersclub {
        $char: "\E024";
    }
    @if $filename == euro-currency {
        $char: "\E025";
    }
    @if $filename == facebook-letter {
        $char: "\E026";
    }
    @if $filename == facebook {
        $char: "\E027";
    }
    @if $filename == functional-arrow-left {
        $char: "\E028";
    }
    @if $filename == functional-arrow-right {
        $char: "\E029";
    }
    @if $filename == google-pay {
        $char: "\E02A";
    }
    @if $filename == google-plus {
        $char: "\E02B";
    }
    @if $filename == heart-empty {
        $char: "\E02C";
    }
    @if $filename == heart {
        $char: "\E02D";
    }
    @if $filename == instagram {
        $char: "\E02E";
    }
    @if $filename == leather {
        $char: "\E02F";
    }
    @if $filename == linkedin {
        $char: "\E030";
    }
    @if $filename == locator-small {
        $char: "\E031";
    }
    @if $filename == locator {
        $char: "\E032";
    }
    @if $filename == maestro {
        $char: "\E033";
    }
    @if $filename == map-world-active {
        $char: "\E034";
    }
    @if $filename == map-world {
        $char: "\E035";
    }
    @if $filename == mastercard {
        $char: "\E036";
    }
    @if $filename == maximize {
        $char: "\E037";
    }
    @if $filename == menu-mobile {
        $char: "\E038";
    }
    @if $filename == menu-opened {
        $char: "\E039";
    }
    @if $filename == menu-tablet {
        $char: "\E03A";
    }
    @if $filename == other {
        $char: "\E03B";
    }
    @if $filename == outersole {
        $char: "\E03C";
    }
    @if $filename == paypal {
        $char: "\E03D";
    }
    @if $filename == percentage {
        $char: "\E03E";
    }
    @if $filename == phone {
        $char: "\E03F";
    }
    @if $filename == pinterest {
        $char: "\E040";
    }
    @if $filename == points-invitation {
        $char: "\E041";
    }
    @if $filename == profile-completed {
        $char: "\E042";
    }
    @if $filename == radio {
        $char: "\E043";
    }
    @if $filename == scalapay {
        $char: "\E044";
    }
    @if $filename == search {
        $char: "\E045";
    }
    @if $filename == subcategories {
        $char: "\E046";
    }
    @if $filename == textile {
        $char: "\E047";
    }
    @if $filename == tiktok {
        $char: "\E048";
    }
    @if $filename == track-green {
        $char: "\E049";
    }
    @if $filename == track {
        $char: "\E04A";
    }
    @if $filename == twitter-pure {
        $char: "\E04B";
    }
    @if $filename == twitter {
        $char: "\E04C";
    }
    @if $filename == upper {
        $char: "\E04D";
    }
    @if $filename == visa {
        $char: "\E04E";
    }
    @if $filename == wishlist-added {
        $char: "\E04F";
    }
    @if $filename == wishlist {
        $char: "\E050";
    }
    @if $filename == youtube-ce {
        $char: "\E051";
    }
    @if $filename == youtube {
        $char: "\E052";
    }
    @if $filename == zoom {
        $char: "\E053";
    }

    @return $char;
}

@mixin icon($filename, $insert: before, $extend: false) {
    &:#{$insert} {
        @if $extend {
            @extend %icon;
        } @else {
            @include icon-styles;
        }
        content: icon-char($filename);

        @content;
    }
}


$icon-account: "\E001"; 
$icon-annivarsary-empty: "\E002"; 
$icon-annivarsary: "\E003"; 
$icon-apek: "\E004"; 
$icon-arrow-down-big: "\E005"; 
$icon-arrow-down: "\E006"; 
$icon-arrow-left-big: "\E007"; 
$icon-arrow-left: "\E008"; 
$icon-arrow-right-big: "\E009"; 
$icon-arrow-right: "\E00A"; 
$icon-arrow-up-big: "\E00B"; 
$icon-arrow-up: "\E00C"; 
$icon-bag: "\E00D"; 
$icon-bata-club-price-cz: "\E00E"; 
$icon-bata-logo-cs: "\E00F"; 
$icon-bata-logo-en: "\E010"; 
$icon-benefit-bata-club: "\E011"; 
$icon-benefit-day-night: "\E012"; 
$icon-benefit-exchange: "\E013"; 
$icon-benefit-free-delivery: "\E014"; 
$icon-benefit-online: "\E015"; 
$icon-benefit-pickup-in-store: "\E016"; 
$icon-benefit-return-less-cs: "\E017"; 
$icon-benefit-return-less: "\E018"; 
$icon-benefit-return: "\E019"; 
$icon-birthday: "\E01A"; 
$icon-bubble-ok: "\E01B"; 
$icon-bubble-warning: "\E01C"; 
$icon-check-mark: "\E01D"; 
$icon-close-remove: "\E01E"; 
$icon-coated-leather: "\E01F"; 
$icon-comb-leather-other: "\E020"; 
$icon-comb-leather-textile: "\E021"; 
$icon-comb-other-textile: "\E022"; 
$icon-czech-currency: "\E023"; 
$icon-dinersclub: "\E024"; 
$icon-euro-currency: "\E025"; 
$icon-facebook-letter: "\E026"; 
$icon-facebook: "\E027"; 
$icon-functional-arrow-left: "\E028"; 
$icon-functional-arrow-right: "\E029"; 
$icon-google-pay: "\E02A"; 
$icon-google-plus: "\E02B"; 
$icon-heart-empty: "\E02C"; 
$icon-heart: "\E02D"; 
$icon-instagram: "\E02E"; 
$icon-leather: "\E02F"; 
$icon-linkedin: "\E030"; 
$icon-locator-small: "\E031"; 
$icon-locator: "\E032"; 
$icon-maestro: "\E033"; 
$icon-map-world-active: "\E034"; 
$icon-map-world: "\E035"; 
$icon-mastercard: "\E036"; 
$icon-maximize: "\E037"; 
$icon-menu-mobile: "\E038"; 
$icon-menu-opened: "\E039"; 
$icon-menu-tablet: "\E03A"; 
$icon-other: "\E03B"; 
$icon-outersole: "\E03C"; 
$icon-paypal: "\E03D"; 
$icon-percentage: "\E03E"; 
$icon-phone: "\E03F"; 
$icon-pinterest: "\E040"; 
$icon-points-invitation: "\E041"; 
$icon-profile-completed: "\E042"; 
$icon-radio: "\E043"; 
$icon-scalapay: "\E044"; 
$icon-search: "\E045"; 
$icon-subcategories: "\E046"; 
$icon-textile: "\E047"; 
$icon-tiktok: "\E048"; 
$icon-track-green: "\E049"; 
$icon-track: "\E04A"; 
$icon-twitter-pure: "\E04B"; 
$icon-twitter: "\E04C"; 
$icon-upper: "\E04D"; 
$icon-visa: "\E04E"; 
$icon-wishlist-added: "\E04F"; 
$icon-wishlist: "\E050"; 
$icon-youtube-ce: "\E051"; 
$icon-youtube: "\E052"; 
$icon-zoom: "\E053"; 

.icon-account {
    @include icon(account);
}
.icon-annivarsary-empty {
    @include icon(annivarsary-empty);
}
.icon-annivarsary {
    @include icon(annivarsary);
}
.icon-apek {
    @include icon(apek);
}
.icon-arrow-down-big {
    @include icon(arrow-down-big);
}
.icon-arrow-down {
    @include icon(arrow-down);
}
.icon-arrow-left-big {
    @include icon(arrow-left-big);
}
.icon-arrow-left {
    @include icon(arrow-left);
}
.icon-arrow-right-big {
    @include icon(arrow-right-big);
}
.icon-arrow-right {
    @include icon(arrow-right);
}
.icon-arrow-up-big {
    @include icon(arrow-up-big);
}
.icon-arrow-up {
    @include icon(arrow-up);
}
.icon-bag {
    @include icon(bag);
}
.icon-bata-club-price-cz {
    @include icon(bata-club-price-cz);
}
.icon-bata-logo-cs {
    @include icon(bata-logo-cs);
}
.icon-bata-logo-en {
    @include icon(bata-logo-en);
}
.icon-benefit-bata-club {
    @include icon(benefit-bata-club);
}
.icon-benefit-day-night {
    @include icon(benefit-day-night);
}
.icon-benefit-exchange {
    @include icon(benefit-exchange);
}
.icon-benefit-free-delivery {
    @include icon(benefit-free-delivery);
}
.icon-benefit-online {
    @include icon(benefit-online);
}
.icon-benefit-pickup-in-store {
    @include icon(benefit-pickup-in-store);
}
.icon-benefit-return-less-cs {
    @include icon(benefit-return-less-cs);
}
.icon-benefit-return-less {
    @include icon(benefit-return-less);
}
.icon-benefit-return {
    @include icon(benefit-return);
}
.icon-birthday {
    @include icon(birthday);
}
.icon-bubble-ok {
    @include icon(bubble-ok);
}
.icon-bubble-warning {
    @include icon(bubble-warning);
}
.icon-check-mark {
    @include icon(check-mark);
}
.icon-close-remove {
    @include icon(close-remove);
}
.icon-coated-leather {
    @include icon(coated-leather);
}
.icon-comb-leather-other {
    @include icon(comb-leather-other);
}
.icon-comb-leather-textile {
    @include icon(comb-leather-textile);
}
.icon-comb-other-textile {
    @include icon(comb-other-textile);
}
.icon-czech-currency {
    @include icon(czech-currency);
}
.icon-dinersclub {
    @include icon(dinersclub);
}
.icon-euro-currency {
    @include icon(euro-currency);
}
.icon-facebook-letter {
    @include icon(facebook-letter);
}
.icon-facebook {
    @include icon(facebook);
}
.icon-functional-arrow-left {
    @include icon(functional-arrow-left);
}
.icon-functional-arrow-right {
    @include icon(functional-arrow-right);
}
.icon-google-pay {
    @include icon(google-pay);
}
.icon-google-plus {
    @include icon(google-plus);
}
.icon-heart-empty {
    @include icon(heart-empty);
}
.icon-heart {
    @include icon(heart);
}
.icon-instagram {
    @include icon(instagram);
}
.icon-leather {
    @include icon(leather);
}
.icon-linkedin {
    @include icon(linkedin);
}
.icon-locator-small {
    @include icon(locator-small);
}
.icon-locator {
    @include icon(locator);
}
.icon-maestro {
    @include icon(maestro);
}
.icon-map-world-active {
    @include icon(map-world-active);
}
.icon-map-world {
    @include icon(map-world);
}
.icon-mastercard {
    @include icon(mastercard);
}
.icon-maximize {
    @include icon(maximize);
}
.icon-menu-mobile {
    @include icon(menu-mobile);
}
.icon-menu-opened {
    @include icon(menu-opened);
}
.icon-menu-tablet {
    @include icon(menu-tablet);
}
.icon-other {
    @include icon(other);
}
.icon-outersole {
    @include icon(outersole);
}
.icon-paypal {
    @include icon(paypal);
}
.icon-percentage {
    @include icon(percentage);
}
.icon-phone {
    @include icon(phone);
}
.icon-pinterest {
    @include icon(pinterest);
}
.icon-points-invitation {
    @include icon(points-invitation);
}
.icon-profile-completed {
    @include icon(profile-completed);
}
.icon-radio {
    @include icon(radio);
}
.icon-scalapay {
    @include icon(scalapay);
}
.icon-search {
    @include icon(search);
}
.icon-subcategories {
    @include icon(subcategories);
}
.icon-textile {
    @include icon(textile);
}
.icon-tiktok {
    @include icon(tiktok);
}
.icon-track-green {
    @include icon(track-green);
}
.icon-track {
    @include icon(track);
}
.icon-twitter-pure {
    @include icon(twitter-pure);
}
.icon-twitter {
    @include icon(twitter);
}
.icon-upper {
    @include icon(upper);
}
.icon-visa {
    @include icon(visa);
}
.icon-wishlist-added {
    @include icon(wishlist-added);
}
.icon-wishlist {
    @include icon(wishlist);
}
.icon-youtube-ce {
    @include icon(youtube-ce);
}
.icon-youtube {
    @include icon(youtube);
}
.icon-zoom {
    @include icon(zoom);
}
