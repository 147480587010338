@import '../_settings/globalSetting';

.cc-georedirect-container {
    background: $white;
    bottom: 0;
    padding: rem-calc(45) rem-calc(68) rem-calc(45) rem-calc(48);
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 0 -5px 14px rgba(0, 0, 0, 0.15);

    @include media-breakpoint-down(xs) {
        padding: rem-calc(48) rem-calc(16) rem-calc(32);
        border-radius: rem-calc(8);
    }

    .cc-georedirect-content {
        align-items: center;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        column-gap: rem-calc(100);

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            row-gap: rem-calc(24);
        }
    }

    .cc-georedirect-text__container {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        .cc-georedirect-text__title {
            font-size: rem-calc(14);
            font-weight: $primary-font-semibold;
            text-transform: uppercase;

            @include media-breakpoint-down(xs) {
                font-size: rem-calc(18);
                font-weight: $primary-font-bold;
                text-transform: none;
                margin-bottom: rem-calc(12);
            }
        }

        .cc-georedirect-text__subtitle {
            font-size: rem-calc(13);

            @include media-breakpoint-down(xs) {
                font-size: rem-calc(15);
                margin-bottom: rem-calc(8);
                line-height: rem-calc(22);
            }
        }
    }



    .cc-georedirect-buttons__container {
        display: flex;
        flex-direction: row;
        column-gap: rem-calc(8);
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            row-gap: rem-calc(8);
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        .cc-country-select {
            font-size: rem-calc(14);
            font-weight: $primary-font-semibold;
            text-align: left;
            text-transform: uppercase;
            width: auto;
            padding: rem-calc(20) rem-calc(40) rem-calc(20) rem-calc(40);

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            column-gap: rem-calc(15);

            @include media-breakpoint-down(md) {
                justify-content: center;
                text-align: center;
                padding: rem-calc(20) rem-calc(130) rem-calc(20) rem-calc(130);
            }

            @include media-breakpoint-down(xs) {
                justify-content: center;
                text-align: center;
                padding: rem-calc(20) rem-calc(70) rem-calc(20) rem-calc(70);
            }
        }

        .cc-georedirect-go-to-country-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: rem-calc(15);

            font-size: rem-calc(14);
            font-weight: $primary-font-semibold;
            text-transform: uppercase;
            text-align: left;

            border: 1px solid #ccc;
            padding: rem-calc(20) rem-calc(40) rem-calc(20) rem-calc(40);
            background: $white;

            @include media-breakpoint-down(md) {
                justify-content: center;
                text-align: center;
                padding: rem-calc(20) rem-calc(130) rem-calc(20) rem-calc(130);
            }

            @include media-breakpoint-down(xs) {
                justify-content: center;
                text-align: center;
                padding: rem-calc(20) rem-calc(70) rem-calc(20) rem-calc(70);
            }
        }

        .cc-georedirect-icon {
            height: rem-calc(20);
            width: rem-calc(20);
        }
    }

    .cc-georedirect-close {
        color: black;
        height: auto;
        position: absolute;
        right: rem-calc(20);
        top: 50%;
        transform: translateY(-4px);

        @include media-breakpoint-down(md) {
            top: rem-calc(4);
            right: 0;
            height: unset;
            padding: rem-calc(16);
            font-weight: $close-font-weight;
            transform: unset;
            &:before{
                font-weight: $close-font-weight;
            }
        }

        &.cc-no-target-country {
            @include media-breakpoint-down(md) {
                top: 0;
                right: 0;
                height: unset;
                padding: rem-calc(16);
                &:before{
                    font-weight: $close-font-weight;
                }
            }
        }
    }
}
