.cc-language-switch {
    .cc-language-switch-image {
        height: rem-calc(15);
        margin-right: rem-calc(5);
        width: auto;
    }
}

.cc-language.modal {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    .cc-language-main.modal-dialog {
        left: 50%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2;

        @include media-breakpoint-down(md) {
            padding: 0 rem-calc(15) rem-calc(20) rem-calc(15);
            width: calc(100% - 30px);
        }

        .cc-overlay-header {
            border: 0;
            justify-content: center;
            padding: rem-calc(50) rem-calc(45) rem-calc(30);

            .cc-overlay-title {
                color: $black;
                font-size: rem-calc(20);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(24);
                margin: 0;
                position: relative;
                text-align: center;
                text-transform: uppercase;
            }

            .cc-close {
                font-size: rem-calc(21);
                position: absolute;
                right: rem-calc(25);
                top: rem-calc(25);
                z-index: 1;
            }
        }

        .cc-overlay-body {
            display: flex;
            padding: 0 rem-calc(45) rem-calc(32);

            .cc-language-switch-list {
                align-items: flex-start;
                display: inline-flex;
                flex-direction: column;
                margin: 0 auto;
                width: auto;

                .cc-language-switch-item {
                    margin-bottom: rem-calc(20);

                    &.cc-selected-language,
                    &:hover {
                        .cc-language-switch-text {
                            color: $red1;
                        }
                    }

                    & + & {
                        border-top: rem-calc(1) solid $white;

                        @include media-breakpoint-up(lg) {
                            border: 0;
                            margin-top: rem-calc(27);
                        }
                    }

                    .cc-language-switch-link {
                        align-items: center;
                        display: flex;
                        font-size: rem-calc(13);
                        text-transform: uppercase;

                        .cc-language-switch-image {
                            border: rem-calc(1) solid $grey3;
                            margin-right: rem-calc(10);
                            width: rem-calc(34);
                            height: auto;
                        }

                        .cc-language-switch-text {
                            font-size: rem-calc(14);
                            font-weight: $primary-font-semibold;
                            margin: 0;
                            position: relative;
                            text-align: left;
                            text-transform: uppercase;

                        }
                    }
                }
            }
        }
    }

    .modal-content {
        border: 0;
        border-radius: 0;
    }

    .close {
        position: absolute;
        right: rem-calc(30);
        top: rem-calc(24);
    }
}
