.cc-pd-modal {
    .modal-dialog {
        max-width: rem-calc(560);
    }

    .modal-content {
        padding: 0 rem-calc(40);
        border-radius: 0;
    }
    .modal-header {
        border: none;
        border-radius: 0;
        display: block;
        padding-top: rem-calc(50);

        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
            max-width: 210px;
            margin: 0 auto;
        }

        .close {
            position: absolute;
            z-index: 1;
            top: rem-calc(20);
            right: rem-calc(20);
            font-size: rem-calc(21);
        }

        .modal-title {
            text-align: center;
            font-weight: $primary-font-semibold;
            color: $black;
            font-size: rem-calc(20);
            line-height: rem-calc(24);
            text-transform: uppercase;
        }
    }

    .modal-body {
        padding: 0;
        font-weight: $primary-font-regular;
        text-align: center;
        color: $black;
        font-size: rem-calc(14);
        line-height: rem-calc(21);

        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
            max-width: 210px;
            margin: 0 auto;
        }
    }

    .modal-footer {
        padding: rem-calc(20) 0 rem-calc(50) 0;
        border: none;

        button {
            max-width: rem-calc(250);
            margin: 0 auto;

            @include media-breakpoint-down(xs) {
                padding-left: 0;
                padding-right: 0;
                max-width: rem-calc(300);
                margin: 0 auto;
            }
        }
    }
}

.modal {
    .modal-header {
        align-items: center;

        .modal-title {
            font-size: rem-calc(20);
            font-weight: $primary-font-semibold;
        }

        .close {
            font-size: rem-calc(21);
            position: absolute;
            right: rem-calc(20);
            top: rem-calc(20);
            z-index: 1;
            transition: all 300ms;
            width: auto;

            &:hover {
                color: $red1;
                transform: rotate(90deg);
                transition: all 300ms;
            }

            .icon-close-remove {
                font-size: rem-calc(12);

                &::before {
                    margin-right: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

.cc-login-modal {
    .modal-dialog {
        bottom: 0;
        margin: 0;
        max-width: 100%;
        width: 100%;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        padding: rem-calc(20);

        @include media-breakpoint-up(sm) {
            padding: rem-calc(100) rem-calc(70);
        }

        @include media-breakpoint-up(md) {
            padding: rem-calc(20);
        }
    }

    .modal-header {
        border: 0;
        margin-bottom: rem-calc(20);
        padding-bottom: 0;
        padding-top: 0;

        @include media-breakpoint-up(sm) {
            margin-bottom: rem-calc(20);
        }

        .close {
            margin: 0;
            padding: 0;
        }
    }

    .modal-body {
        padding-bottom: 0;
        padding-top: 0;
    }

    .cc-login-content-remember {
        display: flex;
        margin-bottom: rem-calc(20);

        @include media-breakpoint-up(sm) {
            margin-bottom: rem-calc(25);
        }
    }

    .forgot-password {
        font-size: rem-calc(13);
        font-weight: $primary-font-medium;
        text-align: right;
        text-decoration: underline;
        width: 100%;
    }

    .cc-form-group {
        .cc-content-checkbox {
            margin-bottom: rem-calc(8);
        }

        .cc-error-message {
            border: 0;
            margin-bottom: 0;
            margin-left: 0;
            min-height: auto;
            padding: 0;
            width: 100%;
        }
    }

    .alert-danger {
        background: transparent;
        border: 0;
        color: $red;
        padding: 0;
    }

    // &.cc-modalSlim {
    //     .modal-header {
    //         @include media-breakpoint-up(sm) {
    //             margin-bottom: rem-calc(30.5);
    //         }
    //     }
    // }
}

.sp_modal__overlay {
    .sp_modal__container {
        padding-top: rem-calc(48);
    }

    .sp_modal__close {

        &::before,
        &::after {
            display: none;
        }
    }
}
