/* ==========================================================================

                        F O O T E R

========================================================================== */
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

:root {
    --color-text: #{$black-warm};
    --color-background: #{$white-warm};
    --color-link-hover: #{$red};
    --color-input-border: #{$black-warm};
    --color-input-border-hover: #{$warm-grey-05};
    --color-nav-title: #{$warm-grey-05};
    --color-hr: #{$warm-grey-04};
    --color-arrow-accordion: #{$warm-grey-05};
    --color-arrow-accordion-active: #{$black-warm};
}

.cc-footer {
    &[data-dark-mode="true"] {
        --color-text: #{$white};
        --color-background: #{$black-warm};
        --color-link-hover: #{$warm-grey-04};
        --color-input-border: #{$warm-grey-04};
        --color-input-border-hover: #{$warm-grey-04};
        --color-nav-title: #{$warm-grey-04};
        --color-hr: #{$warm-grey-02};
        --color-arrow-accordion: #{$warm-grey-04};
        --color-arrow-accordion-active: #{$white};
    }

    .cc-footer-services {
        background: $white;

        @include media-breakpoint-down(xs) {
            border-top: rem-calc(1) solid $grey2;
            padding: rem-calc(28) 0;
        }

        .cc-services-list {
            border-top: rem-calc(1) solid $grey2;
            display: flex;
            font-size: 0;
            padding: rem-calc(45) 0 rem-calc(29);
            text-align: center;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(xs) {
                border: 0;
                display: contents;
            }
        
            @include media-breakpoint-only(sm) {
                justify-content: space-between;
            }
        }

        .cc-services-item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem-calc(16);
            padding: 0 rem-calc(15);
            text-align: center;
            vertical-align: top;

            @include media-breakpoint-down(xs) {
                display: inline-block;
                margin-bottom: 0;
                padding: rem-calc(10) 0;
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                &:not([class*='col-sm']) {
                    &:not([class*='col-md']) {
                        width: 20%;
                    }
                }
            }


            @include media-breakpoint-up(lg) {
                &:not([class*='col-sm']) {
                    &:not([class*='col-md']) {
                        &:not([class*='col-lg']) {
                            width: 20%;
                        }
                    }
                }
            }

            .cc-icon-link {
                align-items: center;
                color: $black;
                display: flex;
                text-decoration: none;
                vertical-align: top;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                    text-align: left;
                }
            }

            .cc-icon-image {
                display: flex;
                justify-content: center;
                margin: 0 rem-calc(8);
                width: rem-calc(100);
            }

            .cc-icon {
                color: $black;
                font-size: rem-calc(60);

                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .cc-item-label {
                color: $black;
                display: block;
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(18);
                padding-left: rem-calc(20);
                text-align: left;
                text-transform: capitalize;

                @include media-breakpoint-down(xs) {
                    padding-top: rem-calc(10);
                    text-align: center;
                    vertical-align: middle;
                    padding-left: 0;
                }
                
                @include media-breakpoint-only(sm) {
                    font-size: rem-calc(13);
                }
            }

            .cc-icon {
                &::before {
                    content: '';
                    width: rem-calc(57);
                    height: rem-calc(57);
                    display: block;
                }

                &.icon-benefit-free-delivery {
                    &::before {
                        background: url('../images/shipping.svg') left top no-repeat;
                    }
                }

                &.icon-benefit-pickup-in-store {
                    &::before {
                        background: url('../images/stores.svg') left top no-repeat;
                    }
                }

                &.icon-benefit-return-less {
                    &::before {
                        background: url('../images/return.svg') left top no-repeat;
                    }
                }

                &.icon-benefit-bata-club {
                    &::before {
                        background: url('../images/bata-club.svg') left top no-repeat;
                    }
                }

                &.icon-benefit-contact-us {
                    &::before {
                        background: url('../images/customer-service.svg') left top no-repeat;
                    }
                }

                &.icon-click-and-collect {
                    &::before {
                        background: url('../images/click-and-collect.svg') left top no-repeat;
                    }
                }
            }
        }
    }

    .cc-footer-main {
        background: var(--color-background);
        padding: rem-calc(48) rem-calc(48) rem-calc(64) rem-calc(48);

        @include media-breakpoint-down(lg) {
            padding-left: rem-calc(24);
            padding-right: rem-calc(24);
        }

        @include media-breakpoint-down(sm) {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
        }

        @include media-breakpoint-down(xs) {
            padding-top: rem-calc(40);
            padding-bottom: 0;
        }

        > .row {
            justify-content: space-between;
        }

        .cc-content-footer-nav {
            @include media-breakpoint-down(xs) {
                padding: rem-calc(16) 0;
            }

            .col-12 {
                @include media-breakpoint-down(xs) {
                    padding: 0;
                    margin: 0 rem-calc(15);
                    flex: 0 1 auto;
                    border-top: 1px solid $warm-grey-02;

                    &:first-child {
                        border-top: 0;
                    }
                }

                &:nth-child(-n + 2) {
                    @include media-breakpoint-only(sm) {
                        padding-bottom: rem-calc(40);
                    }
                }
            }
        }

        .cc-footer-nav-title {
            color: var(--color-nav-title);
            display: block;
            font-size: rem-calc(12);
            font-weight: $primary-font-medium;
            line-height: rem-calc(18);
            padding-bottom: rem-calc(24);
            text-align: left;
            text-transform: uppercase;
            letter-spacing: rem-calc(1);

            @include media-breakpoint-down(xs) {
                cursor: pointer;
                padding: rem-calc(16) 0;
                position: relative;

                &.cc-link-open {
                    color: var(--color-text);
                }
            }

            &::before {
                @include media-breakpoint-down(xs) {
                    @include animation;
                    @include centerY;
                    $svgColor: str-replace('' + $warm-grey-05, '#', '%23');
                    background-color: transparent;
                    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 407.4 224.3' xml:space='preserve'%3E%3Cpolygon fill='#{$svgColor}' points='386.3,0 203.7,181.9 21.2,0 0,21.2 203.7,224.3 407.4,21.2'/%3E%3C/svg%3E");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% !important;
                    content: '';
                    display: block;
                    height: rem-calc(8);
                    position: absolute;
                    right: rem-calc(0);
                    width: rem-calc(16);
                    z-index: 2;
                }
            }

            &.cc-link-open {
                &::before {
                    @include media-breakpoint-down(xs) {
                        transform: rotate(180deg);
                        $svgColor: str-replace('' + $black-warm, '#', '%23');
                        background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 407.4 224.3' xml:space='preserve'%3E%3Cpolygon fill='#{$svgColor}' points='386.3,0 203.7,181.9 21.2,0 0,21.2 203.7,224.3 407.4,21.2'/%3E%3C/svg%3E");
                    }
                }
            }

            &.cc-footer-nav-title-follow {
                @include media-breakpoint-down(xs) {
                    cursor: default;
                    text-align: center;
                    border-top: 1px solid $grey2;
                    padding-top: rem-calc(18);
                }

                &::before {
                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }
            }
        }

        .cc-footer-nav-list {
            display: block;

            @include media-breakpoint-up(sm) {
                display: block !important;
            }

            @include media-breakpoint-down(xs) {
                display: none;
                padding: rem-calc(6) 0 rem-calc(16) 0;
            }

            .cc-footer-nav-item {
                display: block;
                text-align: left;
                margin-bottom: rem-calc(12);

                @include media-breakpoint-down(xs) {
                    margin-bottom: rem-calc(20);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .cc-footer-navigation-link-2 {
                color: var(--color-text);
                font-size: rem-calc(15);
                font-weight: $primary-font-regular;
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.1);

                &:hover,
                &:active,
                &.cc-active {
                    color: var(--color-link-hover);
                    text-decoration: none;
                }
            }
        }

        .cc-footer-main-georedirection__container {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }

    &[data-dark-mode="true"] {
        .cc-footer-nav-title {
            &::before {
                @include media-breakpoint-down(xs) {
                    $svgColor: str-replace('' + $warm-grey-04, '#', '%23');
                    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 407.4 224.3' xml:space='preserve'%3E%3Cpolygon fill='#{$svgColor}' points='386.3,0 203.7,181.9 21.2,0 0,21.2 203.7,224.3 407.4,21.2'/%3E%3C/svg%3E");
                }
            }

            &.cc-link-open {
                &::before {
                    @include media-breakpoint-down(xs) {
                        $svgColor: str-replace('' + $white, '#', '%23');
                        background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 407.4 224.3' xml:space='preserve'%3E%3Cpolygon fill='#{$svgColor}' points='386.3,0 203.7,181.9 21.2,0 0,21.2 203.7,224.3 407.4,21.2'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    .cc-footer-bottom {
        background: var(--color-background);
        padding: 0 rem-calc(48) rem-calc(24) rem-calc(48);

        @include media-breakpoint-down(lg) {
            padding-left: rem-calc(24);
            padding-right: rem-calc(24);
        }

        @include media-breakpoint-down(sm) {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
        }

        .cc-footer-brand {
            color:  var(--color-text);
            display: block;
            font-size: rem-calc(13);
            font-weight: $primary-font-regular;
            line-height: rem-calc(18);
            text-align: right;
            letter-spacing: rem-calc(0.13);

            @include media-breakpoint-down(xs) {
                margin-top: rem-calc(16);
                padding-top: rem-calc(16);
                border-top: 1px solid var(--color-hr);
                text-align: center;
            }
        }

        .cc-content-footer-bottom-elements {
            display: block;
            font-size: 0;
            text-align: left;

            @include media-breakpoint-down(xs) {
                text-align: center;
            }

            .cc-footer-bottom-element {
                display: inline-block;
                font-size: 0;
                margin-right: rem-calc(50);
                vertical-align: middle;

                @include media-breakpoint-down(xs) {
                    display:block;
                }

                &.cc-footer-bottom-element-certification {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                .cc-footer-payments-method {
                    color: $black;
                    display: inline-block;
                    font-size: rem-calc(14);
                    font-weight: $primary-font-semibold;
                    line-height: rem-calc(33);
                    margin-right: rem-calc(15);
                    text-align: left;
                    text-transform: uppercase;
                    vertical-align: middle;

                    @include media-breakpoint-down(md) {
                        display: none;
                        margin-right: 0;
                        padding-bottom: rem-calc(5);
                        text-align: center;
                    }

                    @include media-breakpoint-down(xs) {
                        display: block;
                    }
                }

                .cc-content-footer-payments-icon {
                    @include flexGenerator(row, center, center);
                    flex-wrap: wrap;
                    font-size: 0;
                    gap: rem-calc(16);

                    @include media-breakpoint-down(xs) {
                        justify-content: space-between;
                    }

                    .cc-icon {
                        color: var(--color-text);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: rem-calc(10);
                        line-height: rem-calc(33);
                        text-decoration: none;
                        vertical-align: middle;
                        border: 1px solid var(--color-text);
                        border-radius: rem-calc(4);
                        width: rem-calc(38);
                        height: rem-calc(25);

                        &.icon-visa {
                            font-size: rem-calc(9);
                        }

                        &.icon-scalapay {
                            font-size: rem-calc(6);
                        }

                        &.cc-icon-visa {
                            font-size: rem-calc(9);
                        }

                        &.cc-icon-scalapay {
                            font-size: rem-calc(6);
                        }

                        &.icon-mastercard {
                            font-size: rem-calc(17);
                        }

                        &.icon-paypal {
                            font-size: rem-calc(8);
                        }

                        &.icon-apek {
                            font-size: rem-calc(33);
                        }

                        &.icon-google-pay {
                            font-size: rem-calc(13);
                        }

                        &.icon-aliPay {
                            background: url('../images/paymentIcon--alyPay.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(30);
                            width: rem-calc(26.25);
                        }

                        &.icon-goPay {
                            background: url('../images/paymentIcon--goPay.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(16);
                            width: rem-calc(77.7);
                        }

                        &.icon-linePay {
                            background: url('../images/paymentIcon--linePay.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(16);
                            width: rem-calc(47.5);
                        }

                        &.icon-shopeePay {
                            background: url('../images/paymentIcon--shopeePay.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(16);
                            width: rem-calc(34.5);
                        }

                        &.icon-trueMoney {
                            background: url('../images/paymentIcon--trueMoney.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(16);
                            width: rem-calc(27.5);
                        }

                        &.icon-wechatPay {
                            background: url('../images/paymentIcon--wechatPay.svg') left top no-repeat;
                            background-size: contain;
                            height: rem-calc(24);
                            width: rem-calc(24);
                        }
                    }
                }
            }
        }

        .cc-footer-bottom-up {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: rem-calc(55);

            @include media-breakpoint-down(xs) {
                display: block;
            }

            .cc-footer-bottom-up-item {
                white-space: nowrap;

                &.cc-footer-bottom-legal {
                    width: 100%;
                }
            }


        }

        .cc-content-footer-copy {
            border-top: rem-calc(1) solid var(--color-hr);
            display: block;
            margin-top: rem-calc(24);
            padding-top: rem-calc(24);

            @include media-breakpoint-down(xs) {
                margin-top: 0;
                padding-top: rem-calc(32);
            }

            .cc-footer-copy-text {
                color: var(--color-text);
                display: block;
                font-size: rem-calc(13);
                font-weight: $primary-font-regular;
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.1);
                margin-bottom: 0;
                text-align: center;
            }
        }

        .cc-footer-main-georedirection__container {
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
    }

    .cc-content-footer-social-list {
        display: block;
        font-size: 0;
        padding-top: rem-calc(3);
        text-align: left;

        @include media-breakpoint-down(xs) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid var(--color-hr);
            text-align: center;
            padding: rem-calc(16) 0;
        }

        .cc-footer-social {
            display: inline-block;
            margin-right: rem-calc(28);
            vertical-align: middle;

            &:last-child {
                margin-right: 0;
            }

            .cc-icon {
                color: var(--color-text);
                font-size: rem-calc(20);
                height: rem-calc(20);

                &.icon-line {
                    background: url('../images/socialIcon-line.svg') left top no-repeat;
                    background-size: contain;
                    width: rem-calc(24);
                }

                &.icon-google-plus {
                    &.color-hover {
                        &:hover {
                            color: $google-plus-hover;
                            text-decoration: $google-plus-hover;
                        }
                    }
                }

                &.icon-twitter {
                    &.color-hover {
                        &:hover {
                            color: $twitter-hover;
                            text-decoration: $twitter-hover;
                        }
                    }
                }

                &.icon-facebook {
                        &:hover {
                            color: $facebook-hover;
                            text-decoration: $facebook-hover;
                        }
                }

                &.icon-instagram {
                    &:hover {
                        color: $instagram-hover;
                        text-decoration: $instagram-hover;
                    }
                }

                &.icon-pinterest {
                    &:hover {
                        color: $pinterest-hover;
                        text-decoration: $pinterest-hover;
                    }
                }

                &.icon-linkedin {
                    &:hover {
                        color: $linkedin-hover;
                        text-decoration: $linkedin-hover;
                    }
                }

                &.icon-youtube {
                    height: rem-calc(14);
                    font-size: rem-calc(14);

                    &:hover {
                        color: $youtube-hover;
                        text-decoration: $youtube-hover;
                    }
                }

                &.icon-youtube-ce {
                    &:hover {
                        color: $youtube-hover;
                        text-decoration: $youtube-hover;
                    }
                }
            }

            .cc-icon-instagram {
                &:hover {
                    fill: $instagram-hover;
                    text-decoration: $instagram-hover;
                }
            }

            .cc-icon-youtube {
                &:hover {
                    fill: $youtube-hover;
                    text-decoration: $youtube-hover;
                }
            }

            .cc-icon-linkedin {
                &:hover {
                    fill: $linkedin-hover;
                    text-decoration: $linkedin-hover;
                }
            }

            .cc-icon-pinterest {
                &:hover {
                    fill: $pinterest-hover;
                    text-decoration: $pinterest-hover;
                }
            }
        }
    }

    .cc-footer-main-georedirection__container {
        margin-left: 0;
        align-items: center;
        column-gap: rem-calc(26);

        @include media-breakpoint-down(xs) {
            margin: 0;
            text-align: center;
            border-bottom: 1px solid var(--color-hr);
            border-top: 1px solid var(--color-hr);
            flex-wrap: nowrap;
            justify-content: center;
            column-gap: 0;
        }

        a {
            @include media-breakpoint-down(xs) {
                display: inline-block;
                margin: rem-calc(16) 0;
            }
        }

        > li {
            @include media-breakpoint-down(xs) {
                width: 50%;
            }
        }

        .cc-item-link {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            column-gap: rem-calc(10);
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(1);
            text-transform: uppercase;
            color: var(--color-text);

            @include media-breakpoint-down(xs) {
                flex-direction: column;
                row-gap: rem-calc(6);
            }

            &:hover {
                color: var(--color-link-hover);

                .cc-icon-text {
                    border-color: var(--color-link-hover);
                }
            }

            .cc-icon-locator-small {
                height: rem-calc(20);
                width: rem-calc(16);
            }
        }
    }

    .cc-footer-georedirection__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: rem-calc(10);

        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: $primary-font-medium;
        letter-spacing: rem-calc(1);
        text-transform: uppercase;
        color: var(--color-text);
        transition: all .15s ease-in;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            row-gap: rem-calc(6);
        }

        .cc-footer-georedirection-world-icon {
            height: rem-calc(24);
            width: rem-calc(24);
        }

        .cc-footer-georedirection-right-icon {
            height: rem-calc(18);
            width: rem-calc(18);
        }

        .cc-footer-georedirection-separator {
            display: inline-block;
            vertical-align: middle;
            width: rem-calc(4);
            height: rem-calc(4);
            background: var(--color-hr);
            border-radius: 50%;
            margin: 0 rem-calc(8);
            transition: all .15s ease-in;

            @include media-breakpoint-down(xs) {
                margin: 0 rem-calc(6);
            }
        }

        &:hover {
            color: var(--color-link-hover);

            .cc-footer-georedirection-separator {
                background: var(--color-link-hover);
            }

            .cc-footer-georedirection__text {
                border-color: var(--color-link-hover);
            }
        }
    }

    .cc-footer-georedirection__text,
    .cc-icon-text {
        border-bottom: 1px solid var(--color-text);
    }


    &.cc-footer-checkout {
        .cc-footer-main {
            display: none;
            padding: 0;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        .cc-content-customer-care {
            border-bottom: rem-calc(1) solid $grey2;
            padding: rem-calc(25) 0;

            .cc-customer-care-text {
                color: $black;
                display: block;
                font-size: rem-calc(12);
                font-weight: $primary-font-medium;
                padding: rem-calc(2) 0 rem-calc(3);
                text-align: center;

                a,
                .cc-text-link {
                    color: $black;
                    font-weight: $primary-font-medium;
                    text-decoration: none;
                }
            }
        }

        .cc-footer-bottom {
            padding-top: rem-calc(24);
            padding-bottom: rem-calc(24);

            .row {
                flex-direction: row-reverse;
                align-items: center;

                @include media-breakpoint-down(xs) {
                    flex-direction: column-reverse;
                }
            }

            // TODO: Capire se la scritta la si vuole mantenere o meno
            /*.cc-content-footer-bottom-elements .cc-footer-bottom-element .cc-footer-payments-method {
                @include media-breakpoint-down(md) {
                    display: inline-block;
                    padding: 0 10px 0 0;
                    line-height: rem-calc(30);
                }
                @include media-breakpoint-down(xs) {
                    display: block;
                    padding: 0 0 10px 0;
                    line-height: rem-calc(18);
                }
            }*/
        }
    }

    .cc-footer-custom-section {
        @include media-breakpoint-down(md) {
            margin-bottom: rem-calc(64);
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: rem-calc(48);
        }

        @include media-breakpoint-down(xs) {
            margin-bottom: rem-calc(32);
        }

        &-title {
            font-family: $secondary-font;
            font-weight: $secondary-font-bold;
            font-size: rem-calc(50);
            line-height: rem-calc(56);
            letter-spacing: rem-calc(0.05);
            color: $red;
            text-transform: uppercase;
            margin-bottom: rem-calc(32);

            &--newsletter {
                font-size: rem-calc(40);
                line-height: rem-calc(48);
            }

            @include media-breakpoint-between(sm,md) {
                margin-bottom: 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: rem-calc(38);
                line-height: rem-calc(44);
            }
        }

        &-paragraph {
            font-size: rem-calc(15);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.1);
            color: var(--color-text);
        }

        &-button a {
            font-size: rem-calc(15);
            text-transform: none;
            font-weight: $primary-font-medium;
            margin-top: rem-calc(16);

            @include media-breakpoint-up(lg) {
                display: inline-block;
                margin-top: rem-calc(24);
            }
        }

        .cc-form-group {
            padding-bottom: rem-calc(9);
        }

        .cc-content-form {
            margin-top: rem-calc(16);
        }

        .cc-content-input {
            color: var(--color-text);
            transition: all .15s ease-in;

            .cc-icon-functional-arrow-right {
                transition: all .15s ease-in;
            }

            &:hover {
                .cc-icon-functional-arrow-right {
                    color: var(--color-input-border-hover);
                }
            }

            @include media-breakpoint-up(lg) {
                width: 75%;
            }
        }

        .cc-input {
            margin: 0;
            min-height: rem-calc(36);
            padding: rem-calc(8) rem-calc(35) rem-calc(8) 0;
            border: 0;
            border-bottom: rem-calc(1) solid var(--color-input-border);
            color: var(--color-text);
            background: transparent;
            border-radius: 0;

            &::placeholder {
                opacity: 1;
                color: var(--color-text);
                transition: all .15s ease-in;
            }

            &:hover,
            &:focus {
                border-color: var(--color-input-border-hover) !important;

                &::placeholder {
                    color: $warm-grey-05;
                }
            }
        }

        .cc-icon-submit-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: rem-calc(8) rem-calc(2);
        }

        .cc-icon-functional-arrow-right {
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }
}
