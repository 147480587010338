@import "_settings/globalSetting";

.cc-modal-animation.modal.fade {
    .modal-dialog {
        transition: transform 0.6s cubic-bezier(0, 0, 0, 1);

        @include media-breakpoint-up(sm) {
            transform: translate(100%, 0);
        }
    }

    &.show {
        .modal-dialog {
            @include media-breakpoint-up(sm) {
                transform: translate(0, 0);
            }
        }
    }
}

.cc-common-modal {
    color: $black;

    .modal-dialog {
        max-width: none;
        margin: 0.5rem 20px;

        @include media-breakpoint-up(sm) {
            max-width: rem-calc(740);
            margin: 1.75rem auto;
        }

        .modal-content {
            border-radius: 0;
            border: 0;

            .modal-header {
                text-transform: uppercase;
                text-align: center;
                padding: 0 20px;
                padding-top: 50px;
                border-bottom: 0;

                @include media-breakpoint-up(sm) {
                    padding: 0 58px;
                    padding-top: 50px;
                }

                .modal-title {
                    width: 100%;
                    font-size: rem-calc(25);
                    font-weight: $primary-font-semibold;
                    line-height: rem-calc(30);
                }

                .close {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    padding: 20px;
                    opacity: 1;

                    &:hover {
                        opacity: 0.7;
                    }

                    .icon-close-remove {
                        font-size: rem-calc(12);
                    }
                }
            }

            .cc-subtitle {
                &:not(.modal-body) {
                    text-transform: uppercase;
                }
                display: block;
                text-align: center;
                border-bottom: rem-calc(10);
                width: 100%;
                font-size: rem-calc(20);
                font-weight: $primary-font-regular;
                padding-bottom: rem-calc(15);
                font-style: italic;
            }

            .modal-body {
                padding: rem-calc(15) rem-calc(20) 0;
                font-size: rem-calc(14);

                @include media-breakpoint-up(sm) {
                    padding: rem-calc(15) rem-calc(58) rem-calc(18);
                }

                /*
                Overrides styling that doesn't apply to
                abandoned cart pop up
                */
                #abandonedCartRegistrationForm {

                    .cc-content-radio {
                        margin-bottom: rem-calc(16);
                    }

                    .cc-input {
                        margin-bottom: rem-calc(16);

                        &.is-invalid {
                            margin-bottom: 0;
                        }
                    }

                    .cc-error-message {
                        margin-top: rem-calc(8)
                    }

                    .abandonedCart-checkbox {
                        margin-bottom: rem-calc(16);

                        .cc-label-description {
                            font-weight: 400;
                            font-size: rem-calc(12.8);
                            text-align: left;

                            &::before {
                                top: rem-calc(5)
                            }

                            &::after {
                                top: rem-calc(5)
                            }
                        }

                        .cc-error-message {
                            margin-top: 0;
                        }
                    }

                    .cc-button-type-1 {
                        width: 50%;
                        margin: 0 auto;
                    }
                }

                .cc-legend-required {

                    display: block;
                    margin-bottom: rem-calc(16);
                    font-size: rem-calc(11.2);

                    &:before {
                        content: '*';
                        display: inline;
                        font-weight: $primary-font-semibold;
                        color: $red1;
                        font-size: rem-calc(13);
                        padding-right: rem-calc(5);
                    }
                }

                .cc-radio-label {
                    padding-right: rem-calc(15);
                }

                .cc-registration-row {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0 rem-calc(-15);

                    .cc-registration-col {
                        width: 50%;
                        max-width: 50%;
                        flex: 0 0 50%;
                        padding: 0 rem-calc(15);

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                    }
                }

                .cc-label-description {

                    font-size: rem-calc(12);
                    text-align: center;

                    a {
                        text-decoration: underline;
                        font-weight: $primary-font-semibold;
                    }
                }
            }

            .modal-footer {
                border-top: 0;
                display: inline-flex;
                justify-content: center;

                &.cc-subtitle {
                    display: block;
                    text-align: center;
                    border-bottom: 10px;
                    width: 100%;
                    font-size: 18px;
                    font-weight: $primary-font-medium;
                    padding-bottom: 15px;
                }

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }

                .cc-logo {
                    /* Necessary for Firefox */
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .cc-headerBar-logo-svg {
                        max-height: rem-calc(30);
                        margin-bottom: rem-calc(15);
                    }
                }
            }
        }
    }
}

.modal {
    .modal-content {
        .modal-header {
            .cc-productLimit {
                &:after {
                    content: '';
                    display: block;
                    width: rem-calc(40);
                    height: 1px;
                    background: $red1;
                    position: absolute;
                    left: 50%;
                    margin-left: rem-calc(-20);
                }
            }
        }
    }
}
