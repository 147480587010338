
*,
*::before,
*::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
}

html,
body {
    color: $black;
    display: flex;
    flex-direction: column;
    font-weight: $primary-font-regular;

    >img {
        height: 0;
    }
}

.cc-mask-input-text {
    -webkit-text-security: disc;
    -moz-text-security: disc;

    &:focus {
        -webkit-text-security: none;
    }
}

.container {
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);

    @include media-breakpoint-up(lg) {
        padding-left: rem-calc(24);
        padding-right: rem-calc(24);
    }
}

.cc-container-full {
    margin: 0 auto;
    padding: 0 rem-calc(40);
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 0 rem-calc(15);
    }
}

.cc-container-small {
    margin: 0 auto;
    max-width: rem-calc(1250);
    padding: 0 rem-calc(15);
    width: 100%;
}

.cc-container-xsmall {
    margin: 0 auto;
    max-width: rem-calc(730);
    padding: 0 rem-calc(16);
    width: 100%;
}

.cc-container-xxsmall {
    margin: 0 auto;
    max-width: rem-calc(490);
    padding: 0 rem-calc(15);
    width: 100%;
}

.cc-no-scroll {
    height: 100%;
    left: 0;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden !important;
    top: 0;
    width: 100%;

    body {
        overflow: initial;
    }
}

.cc-hide {
    display: none;
}

.cc-fadeout {
    opacity: 0 !important;
    transition: 300ms !important;
}

.no-show-stores,
.no-show-store,
.no-show-in-sth,
.no-show-in-sts {
    display: none;
}

.show-in-sts {
    display: block !important;
}

.disabled-custom-select {
    background: none;
    cursor: not-allowed;
    pointer-events: none;
}


.cc-clearfix:before,
.cc-clearfix:after {
    content: " ";
    display: table;
}

.cc-clearfix:after {
    clear: both;
}

.cc-uppercase {
    text-transform: uppercase !important;
}

.cc-icon {
    display: inline-block;
    vertical-align: middle;
}

.cc-main-wrapper {
    @include animation();
    background: $white;
    position: relative;

    &.cc-background-overlay-menu:after {
        background: $black;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: .3;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        z-index: 2;
    }
}

// Icons

svg.small {
    height: rem-calc(16);
    width: rem-calc(16);
}

svg.medium {
    height: rem-calc(20);
    width: rem-calc(20);
}

svg.large {
    height: rem-calc(24);
    width: rem-calc(24);
}


/* ===========================
        TYPOGRAPHY
=========================== */
.cc-h1 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-size: rem-calc(64);
    font-weight: $primary-font-bold;
    letter-spacing: rem-calc(0.32);
    line-height: rem-calc(70);
    padding-bottom: rem-calc(15);
    text-align: left;
    text-transform: uppercase;

    &--secondary {
        font-family: $primary-font;
        font-size: rem-calc(58);
        letter-spacing: normal;
        line-height: rem-calc(66);
        text-transform: none;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(50);
            line-height: rem-calc(56);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: rem-calc(52);
        line-height: rem-calc(60);
    }
}

.cc-h2 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-weight: $secondary-font-bold;
    font-size: rem-calc(50);
    letter-spacing: rem-calc(0.25);
    line-height: rem-calc(56);
    padding-bottom: rem-calc(10);
    text-align: left;
    text-transform: uppercase;

    // NOTE: BATA REDESIGN
    &--secondary {
        font-family: $primary-font;
        font-size: rem-calc(48);
        letter-spacing: normal;
        line-height: rem-calc(56);
        text-transform: none;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(42);
            line-height: rem-calc(48);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: rem-calc(46);
        line-height: rem-calc(54);
    }
}

.cc-h3 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-weight: $secondary-font-bold;
    font-size: rem-calc(40);
    letter-spacing: rem-calc(0.2);
    line-height: rem-calc(48);
    padding-bottom: rem-calc(5);
    text-align: left;
    text-transform: uppercase;

    // NOTE: BATA REDESIGN
    &--secondary {
        font-family: $primary-font;
        font-size: rem-calc(38);
        letter-spacing: normal;
        line-height: rem-calc(46);
        text-transform: none;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(34);
            line-height: rem-calc(40);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: rem-calc(38);
        line-height: rem-calc(44);
    }
}

.cc-h4 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-weight: $secondary-font-bold;
    font-size: rem-calc(32);
    letter-spacing: rem-calc(0.16);
    line-height: rem-calc(40);
    padding-bottom: rem-calc(5);
    text-align: left;
    text-transform: uppercase;

    // NOTE: BATA REDESIGN
    &--secondary {
        font-family: $primary-font;
        font-size: rem-calc(30);
        letter-spacing: normal;
        line-height: rem-calc(38);
        text-transform: none;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(28);
            line-height: rem-calc(34);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: rem-calc(28);
        line-height: rem-calc(36);
    }
}

.cc-h5 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-weight: $secondary-font-bold;
    font-size: rem-calc(22);
    letter-spacing: rem-calc(0.11);
    line-height: rem-calc(28);
    padding-bottom: rem-calc(5);
    text-align: left;
    text-transform: uppercase;

    // NOTE: BATA REDESIGN
    &--secondary {
        font-family: $primary-font;
        font-size: rem-calc(24);
        letter-spacing: normal;
        line-height: rem-calc(30);
        text-transform: none;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(24);
            line-height: rem-calc(30);
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: rem-calc(22);
        line-height: rem-calc(28);
    }
}

.cc-h6 {
    color: $black-warm;
    display: block;
    font-family: $secondary-font;
    font-weight: $secondary-font-bold;
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    padding-bottom: rem-calc(5);
    text-align: left;
    text-transform: uppercase;

    // NOTE: BATA REDESIGN
    &--secondary {
        font-family: $primary-font;
        text-transform: none;
    }
}

.cc-p {
    color: $black-warm;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(15);
    font-weight: $primary-font-regular;
    line-height: rem-calc(22);
    padding-bottom: rem-calc(10);
    text-align: left;
}

strong {
    font-family: $primary-font;
    font-weight: $primary-font-bold;
}

a {
    @include animation();
    -webkit-text-decoration-skip: objects;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    font-family: $primary-font;

    &:active,
    &:hover,
    &:focus {
        color: inherit;
        outline: none;
        outline-width: 0;
        text-decoration: none;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

button {
    @include animation();
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;

    &:focus,
    &:active {
        outline: none;
    }
}

.cc-slot-img-bottom {
    .cc-img {
        display: block;
        width: 100%;

        @include media-breakpoint-down(xs) {
            margin: 0 rem-calc(-15);
            width: calc(100% + 30px);
        }
    }
}

.cc-footer-navigation-link {
    background: transparent;
    border: 0;
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-medium;
    line-height: rem-calc(16);
    padding: rem-calc(7) 0;
    text-align: left;
    text-decoration: none;
    vertical-align: top;

    &:hover,
    &:active,
    &.cc-active {
        color: $black;
        text-decoration: none;
    }
}

.cc-footer-navigation-link-2 {
    background: transparent;
    border: 0;
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-medium;
    line-height: rem-calc(16);
    padding: rem-calc(3) 0;
    text-align: left;
    text-decoration: none;
    vertical-align: top;

    &:hover,
    &:active,
    &.cc-active {
        color: $black;
        text-decoration: underline;
    }
}

.cc-link-type-1 {
    background: transparent;
    border: 0;
    color: $black-warm;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(15);
    font-weight: $primary-font-medium;
    line-height: rem-calc(22);
    padding: rem-calc(7) 0;
    text-align: left;
    vertical-align: top;

    &:hover,
    &:active,
    &.cc-active {
        color: $red;
        text-decoration: none;
    }

    &:disabled,
    &.cc-disabled {
        color: $warm-grey-04;
    }

    &--light {
        color: $white;

        &:hover,
        &:active,
        &.cc-active {
            color: $warm-grey-04;
        }

        &:disabled,
        &.cc-disabled {
            color: $warm-grey-05;
        }
    }
}

.cc-link-type-2 {
    background: transparent;
    border: 0;
    color: $black-warm;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(15);
    font-weight: $primary-font-medium;
    line-height: rem-calc(22);
    padding: rem-calc(7) 0;
    text-align: left;
    vertical-align: top;
    text-decoration: underline;
    text-underline-offset: rem-calc(4);

    &:hover,
    &:active,
    &.cc-active {
        color: $red;
        text-decoration: underline;
    }

    &:disabled,
    &.cc-disabled {
        color: $warm-grey-04
    }

    &--light {
        color: $white;

        &:hover,
        &:active,
        &.cc-active {
            color: $warm-grey-04;
        }

        &:disabled,
        &.cc-disabled {
            color: $warm-grey-05;
        }
    }
}

.cc-link-type-3 {
    background: transparent;
    border: 0;
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(13);
    font-weight: $primary-font-regular;
    line-height: rem-calc(16);
    padding: rem-calc(7) 0;
    position: relative;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;
    vertical-align: top;

    &:hover,
    &:active,
    &.cc-active {
        color: $black;
        text-decoration: none;
    }
}



/* ===========================
        BUTTONS
=========================== */
.cc-content-button {
    margin-bottom: rem-calc(15);
    text-align: left;
}

.cc-button-type-1 {
    @include animation();
    background: $black-warm;
    border: rem-calc(1) solid $black-warm;
    border-radius: rem-calc(2);
    color: $white;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-wrap: nowrap;
    width: 100%;

    &:focus {
        color: $white;
    }

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            animation: mouseoverButtonType1 300ms;
            background-color: $red;
            border-color: $red;
            color: $white;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: $warm-grey-04;
        border-color: $warm-grey-04;
        color: $white;
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-2 {
    @include animation();
    background: transparent;
    border: rem-calc(1) solid $black-warm;
    color: $black-warm;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            border-color: $red;
            color: $red;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        border-color: $warm-grey-04;
        color: $warm-grey-04;
        cursor: default;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-3 {
    @include animation();
    background: $white;
    border: rem-calc(1) solid $white;
    color: $black-warm;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            color: $red;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: rgba($white, .2);
        color: $warm-grey-04;
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-4 {
    @include animation();
    background: transparent;
    border: rem-calc(1) solid $white;
    color: $white;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            color: $white;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: rgba($white, .2);
        color: rgba($white, .2);
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-5 {
    @include animation();
    background: $red;
    border: rem-calc(1) solid $red;
    color: $white;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    letter-spacing: rem-calc(1.2);
    height: rem-calc(48);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            background: $ruby-red;
            border-color: $ruby-red;
            color: $white;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: $warm-grey-04;
        border-color: $warm-grey-04;
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-6 {
    @include animation();
    background: transparent;
    border: rem-calc(1) solid $red;
    color: $red;
    cursor: pointer;
    display: block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            border-color: $ruby-red;
            color: $ruby-red;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: transparent;
        border-color: $warm-grey-04;
        color: $warm-grey-04;
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}

.cc-button-type-7 {
    @include animation();
    background: $black;
    border: rem-calc(1) solid $black;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $primary-font;
    font-size: rem-calc(12);
    font-weight: $primary-font-extra-bold;
    height: rem-calc(48);
    letter-spacing: rem-calc(1.2);
    line-height: rem-calc(18);
    padding: rem-calc(15) rem-calc(24);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: top;

    &:hover:not(:disabled):not(.cc-disabled),
    &.cc-hover:not(:disabled):not(.cc-disabled) {
        @include media-breakpoint-up(lg) {
            background: $white;
            color: $black;
            text-decoration: none;
        }
    }

    &:disabled,
    &.cc-disabled,
    &:disabled:hover,
    &.cc-disabled:hover {
        background: $grey3;
        background: transparent;
        border-color: $grey3;
        color: $white;
        cursor: default;
        text-decoration: none;
    }

    &-secondary {
        font-size: rem-calc(15);
        font-weight: $primary-font-medium;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        text-transform: none;
    }

    &.low {
        height: rem-calc(42);
        padding: rem-calc(12) rem-calc(20);
    }
}



/* ===========================
        FORM ELEMENTS
=========================== */
.cc-form-group {
    padding-bottom: rem-calc(5);
    position: relative;

    &.required {
        .cc-label:after {
            content: '*';
            display: inline;
            font-size: rem-calc(13);
            font-weight: $primary-font-regular;
        }
    }

    &.remember-me {
        width: 100%;
    }

    .cc-content-input {
        position: relative;

        &.cc-hide-underlined {
            @include media-breakpoint-down(sm) {
                background-color: $white;
            }
        }

        &.cc-content-input-password {
            position: relative;
        }

        .input-padding {
            padding-right: rem-calc(30);
        }
    }

    .cc-content-select,
    .cc-content-textarea {
        position: relative;
    }

    .cc-label,
    .cc-required-label {
        color: $black-warm;
        display: block;
        font-family: $primary-font;
        font-size: rem-calc(13);
        letter-spacing: rem-calc(0.13);
        line-height: rem-calc(18);
        font-weight: $primary-font-regular;
        margin: 0;
        text-align: left;

        .cc-symbol {
            color: $black-warm;
        }
    }

    &.cc-rut {
        margin-bottom: rem-calc(35);
    }

    .cc-input {
        @include animation();
        background: $white;
        border: rem-calc(1) solid $warm-grey-05;
        border-radius: rem-calc(2);
        color: $black-warm;
        display: block;
        margin-bottom: rem-calc(4);
        font-family: $primary-font;
        font-size: rem-calc(16);
        letter-spacing: rem-calc(0.13);
        line-height: rem-calc(18);
        font-weight: $primary-font-regular;
        padding: rem-calc(13) rem-calc(15) rem-calc(4);
        height: auto;
        min-height: rem-calc(44);
        text-align: left;
        width: 100%;

        &::placeholder {
            opacity: 0;
        }

        &:hover,
        &.cc-hover,
        &:active {
            border-color: $warm-grey-06 !important;
            box-shadow: none !important;
            outline: none !important;
        }

        &:focus {
            border-color: $black-warm !important;
            box-shadow: none !important;
            outline: none !important;
        }

        &.cc-valid {
            border-color: $black !important;
        }

        &:disabled,
        &:read-only {
            border-color: $warm-grey-04 !important;
            color: $warm-grey-04;
        }

        &.cc-invalid,
        &.is-invalid {
            border-color: $red !important;
        }

        &~label {
            @include animation();
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            letter-spacing: rem-calc(0.22);
            padding-top: rem-calc(13);
            padding-left: rem-calc(15);
            pointer-events: none;
        }

        &:focus~label,
        &:not(:placeholder-shown)~label,
        &:-webkit-autofill ~ label,
        &.cc-force-placeholder-top-position ~ label, // For example, in LATAM countries, the placeholder must be always on top, because the prefix of the phone is not part of the value of the input
        &:autofill ~ label,
        &:focus ~ label:after {
            color: $warm-grey-04;
            font-size: rem-calc(13);
            letter-spacing: rem-calc(0.22);
            line-height: rem-calc(16);
            padding-top: rem-calc(4);
        }
    }

    .cc-content-select {
        width: 100%;

        .cc-select {
            margin-bottom: rem-calc(25);
        }

        .bootstrap-select {
            &:not([class*="col-"]):not([class*="form-control-custom"]):not(.input-group-btn) {
                width: 100%;
            }

            .dropdown-toggle {
                @include boxShadow(none);
                background: $white;
                border: rem-calc(1) solid $warm-grey-05;
                border-radius: rem-calc(2);
                color: $black-warm !important;
                display: block;
                font-family: $primary-font;
                font-size: rem-calc(13);
                font-weight: $primary-font-regular;
                padding: rem-calc(12) rem-calc(40) rem-calc(12) rem-calc(12);
                position: relative;
                text-align: left;

                &::after {
                    color: $black;
                    font-size: rem-calc(25);
                    margin: 0;
                    margin: 0;
                    margin-top: rem-calc(-3);
                    padding: 0;
                    position: absolute;
                    right: rem-calc(13);
                    top: 50%;
                    z-index: 2;
                }

                &[aria-expanded='true'] {
                    &::after {
                        transform: rotateZ(180deg);
                        transition: 300ms;
                    }
                }

                .cc-content-arrow {
                    background: $grey2;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: rem-calc(35);

                    &::before {
                        background: $grey2;
                    }
                }

                .cc-svg-select-arrow-down {
                    @include centerXY();
                    position: absolute;
                }

                &::after {
                    $svgColor: str-replace(''+ $black-warm, '#', '%23');
                    background-color: transparent;
                    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16.2 9.1' xml:space='preserve'%3E%3Cpolygon fill='#{$svgColor}' points='15.4,0 8.1,7.4 0.9,0 0,0.8 8.1,9.1 16.2,0.8'/%3E%3C/svg%3E");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    border: 0;
                    color: transparent;
                    display: block;
                    display: block;
                    height: rem-calc(6);
                    margin: rem-calc(-4) 0 0;
                    padding: 0;
                    position: absolute;
                    right: rem-calc(13);
                    top: 50%;
                    transform: rotateZ(0deg);
                    transition: 300ms;
                    width: rem-calc(12);
                }

                .filter-option {
                    color: inherit;
                    font: inherit;
                    font-style: normal;
                    text-align: left;
                    white-space: normal;

                    .cc-select-name {
                        font-style: normal;
                    }
                }

                &:focus,
                &:active {
                    background: $white;
                    border-color: $warm-grey-06;
                    color: $black-warm;
                    outline: none !important;
                }

                &:disabled,
                &:read-only {
                    border-color: $warm-grey-04 !important;
                    color: $warm-grey-04;
                }

                &.bs-invalid {
                    border-color: $red !important;
                }
            }

            .dropdown-menu {
                border-radius: rem-calc(2);
                border: rem-calc(1) solid $black-warm;
                margin: rem-calc(4) 0 0;
                padding: 0;

                &.inner {
                    border: 0;
                    padding: rem-calc(16) rem-calc(12) rem-calc(0);
                }

                li {
                    display: block;
                    width: 100%;

                    a.dropdown-item {
                        @include animation();
                        color: $black-warm;
                        display: block;
                        font-family: $primary-font;
                        font-size: rem-calc(13);
                        font-weight: $primary-font-regular;
                        margin-bottom: rem-calc(16);
                        text-align: left;
                        padding: 0;

                        &:hover {
                            color: $red;
                            background-color: transparent;
                        }
                    }

                    &.selected,
                    &.active {
                        a {
                            background: $white;
                            color: $black-warm;
                        }
                    }
                }
            }
        }
    }

    .cc-textarea {
        @include animation();
        background: $white;
        border: rem-calc(1) solid $warm-grey-05;
        border-radius: rem-calc(2);
        color: $black-warm;
        display: block;
        margin-bottom: rem-calc(4);
        font-family: $primary-font;
        font-size: rem-calc(13);
        line-height: rem-calc(18);
        font-weight: $primary-font-regular;
        padding: rem-calc(18) rem-calc(15) rem-calc(13);
        height: rem-calc(166);
        resize: none;
        text-align: left;
        width: 100%;

        &::placeholder {
            opacity: 0;
        }

        &:hover,
        &.cc-hover,
        &:active {
            border-color: $warm-grey-06 !important;
            box-shadow: none !important;
            outline: none !important;
        }

        &:focus {
            border-color: $black-warm !important;
            box-shadow: none !important;
            outline: none !important;
        }

        &.cc-valid {
            border-color: $black !important;
        }

        &:disabled,
        &:read-only {
            border-color: $warm-grey-04 !important;
            color: $warm-grey-04;
        }

        &.cc-invalid,
        &.is-invalid {
            border-color: $red !important;
        }

        &~label {
            @include animation();
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            padding-top: rem-calc(13);
            padding-left: rem-calc(15);
            pointer-events: none;
        }

        &:focus~label,
        &:not(:placeholder-shown)~label,
        &:-webkit-autofill ~ label,
        &:autofill ~ label {
            padding-top: rem-calc(4);
            font-size: rem-calc(11);
            color: $warm-grey-04;
            line-height: rem-calc(16);
        }
    }

    .cc-error-message {
        //display: block;
        color: $red;
        font-size: 0;
        //margin-top: rem-calc(-20);
        text-align: left;
        padding-left: rem-calc(12);

        .cc-icon {
            display: none;
            height: rem-calc(20);
            margin-right: rem-calc(7);
            padding-top: rem-calc(4);
            vertical-align: middle;

            &::before {
                font-size: rem-calc(14);
            }
        }

        .cc-text-message {
            color: $red;
            display: inline-block;
            font-family: $primary-font;
            font-size: rem-calc(11);
            font-weight: $primary-font-regular;
            letter-spacing: rem-calc(0.22);
            line-height: rem-calc(16);
            max-width: calc(100% - 30px);
            text-align: left;
            vertical-align: middle;
        }

        &.cc-error-message-double-width {
            @include media-breakpoint-up(sm) {
                width: 200%;
            }
        }
    }

    // NOTE: BATA REDESIGN
    .cc-pwd-toggler,
    .cc-input-link {
        position: absolute;
        right: rem-calc(-10);
        z-index: 2;
        right: rem-calc(12);
        top: 50%;
        transform: translateY(-50%);
    }

    .cc-valid-icon {
        background: $white;
        color: $green1;
        display: block;
        position: absolute;
        right: rem-calc(-10);
        z-index: 2;
        right: rem-calc(12);
        top: 50%;
        transform: translateY(-50%);

        .cc-icon {
            display: none;
            height: rem-calc(20);

            &::before {
                font-size: rem-calc(18);
            }
        }
    }

    .cc-content-checkbox {
        margin-bottom: rem-calc(25);
        text-align: left;

        .cc-check-label {
            color: $black-warm;
            cursor: pointer;
            font-family: $primary-font;
            font-size: rem-calc(15);
            font-weight: $primary-font-regular;
            letter-spacing: rem-calc(0.15);
            line-height: rem-calc(22);
            padding-left: rem-calc(28);
            text-align: left;

            a {
                text-decoration: underline;
                text-underline-offset: rem-calc(4);
                &:hover {
                    color: $red;
                }
            }

            &::before,
            &::after {
                background: transparent;
                border: rem-calc(1) solid $black-warm;
                border-radius: rem-calc(2);
                height: rem-calc(20);
                left: 0;
                top: 0;
                width: rem-calc(20);
            }

            &::after {
                @include animation();
                background-image: unset !important;
                background-color: $black-warm;
                transform: scale(0);
                z-index: -1;
            }

            &:hover,
            &.cc-hover {
                &::before,
                &::after {
                    @include media-breakpoint-up (lg) {
                        border-color: $red !important;
                    }
                }

                &::after {
                    @include media-breakpoint-up (lg) {
                        background-color: $red;
                    }
                }
            }

            .cc-symbol {
                color: $red;
            }
        }

        .cc-checkbox {
            &:not(:disabled):active~.cc-check-label::before {
                background-color: transparent;
                border-color: $black-warm;
            }

            &:focus~.cc-check-label:before {
                border-color: $black-warm;
                box-shadow: none;
            }

            &:checked~.cc-check-label:before {
                color: $black-warm;
            }

            &:checked~.cc-check-label:after {
                transform: scale(0.6);
                z-index: 0;
            }

            &.is-invalid~.cc-check-label:before {
                background: transparent;
                border-color: $red !important;
            }

            &:disabled:checked~.cc-check-label:before {
                background: transparent;
                border-color: $cold-grey-04;
            }

            &:disabled:checked~.cc-check-label:after {
                background-color: $cold-grey-04;
                border-color: $cold-grey-04;
            }

            &:disabled~.cc-check-label {
                color: $cold-grey-04;
                cursor: default;

                &::before {
                    border-color: $cold-grey-04 !important;
                }
            }

            &.is-invalid {
                &+label:before {
                    border-color: $red !important;
                }
            }
        }
    }

    .cc-content-radio {
        margin-bottom: rem-calc(25);
        text-align: left;

        .cc-invalid-address-warning {
            display: none;
        }

        &.cc-active {

            .cc-invalid-address-warning {
                display: inline-block;
                font-size: rem-calc(12);
                font-weight: $primary-font-semibold;
                color: $orange1;
                max-width: fit-content;
                padding: rem-calc(2) rem-calc(6);
                border: rem-calc(1) solid $orange1;
                text-transform: uppercase;
            }
        }

        .cc-radio {
            display: inline-block;
            margin-right: rem-calc(15);
            vertical-align: middle;

            &:last-child {
                margin-right: 0;
            }
        }

        input[type="radio"] {

            &:not(:checked),
            &:checked {
                left: rem-calc(-9999);
                opacity: 0;
                position: absolute;
            }

            &:not(:checked)+label,
            &:checked+label {
                @include animation();
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
                color: $black-warm;
                cursor: pointer;
                display: inline-block;
                font-family: $primary-font;
                font-size: rem-calc(15);
                font-weight: $primary-font-medium;
                height: rem-calc(23);
                letter-spacing: rem-calc(0.15);
                line-height: rem-calc(23);
                padding-left: rem-calc(28);
                position: relative;
                text-align: left;
                user-select: none;
            }

            &+label:before,
            &+label:after {
                @include animation();
                content: '';
                height: rem-calc(20);
                left: 0;
                margin: 0;
                position: absolute;
                top: 0;
                width: rem-calc(20);
                z-index: 0;
            }

            &:not(:checked)+label:before,
            &:not(:checked)+label:after,
            &:checked+label:before,
            &:checked+label:after {
                border-radius: 50%;
            }

            &:not(:checked)+label:before,
            &:not(:checked)+label:after {
                border: rem-calc(1) solid $black-warm;
            }

            &:not(:checked)+label:after {
                -webkit-transform: scale(0);
                transform: scale(0);
                z-index: -1;
            }

            &:checked+label:before {
                border: rem-calc(1) solid $black-warm;
            }

            &:checked+label:after,
            &:checked+label:before {
                background: transparent;
                border: rem-calc(1) solid $black-warm;
            }

            &:checked+label:after {
                background-color: $black-warm;
                z-index: 0;
            }

            &:checked+label:after {
                -webkit-transform: scale(.5);
                transform: scale(.5);
            }

            &.tabbed:focus+label:before {
                box-shadow: none;
            }

            &:disabled:checked+label:before {
                border: rem-calc(1) solid $cold-grey-04;
                cursor: default;
            }

            &:disabled:checked+label:after {
                background: $cold-grey-04;
                border: 0;
                cursor: default;
            }

            &:disabled:not(:checked)+label:before,
            &:disabled:checked+label:before {
                background-color: transparent;
                border-color: $cold-grey-04;
            }

            &:disabled+label {
                color: $cold-grey-04;
                cursor: default;
                opacity: 1;

                &::before {
                    border-color: $cold-grey-04 !important;
                }
            }

            &:disabled:not(:checked)+label::before {
                border-color: $cold-grey-04;
            }

            &:disabled:checked+label::after {
                background-color: $cold-grey-04;
                border-color: $cold-grey-04;
            }

            &.is-invalid {
                &+label::before {
                    border-color: $red !important;
                }
            }
        }

        .cc-radio-label {
            margin-bottom: 0;

            &:hover,
            &.cc-hover {
                &::before,
                &::after {
                    border-color: $red !important;
                }

                &::after {
                    background-color: $red !important;
                }
            }
        }
    }

    &.cc-form-group-centered {

        .cc-label,
        .cc-input {
            text-align: center;
        }
    }

    .cc-form-additional-text {
        color: $grey1;
        font-size: rem-calc(12);
        font-weight: $primary-font-medium;
        line-height: rem-calc(21);
    }

    &__pwdIcon.iconClassCommon {
        position: absolute;
        top: rem-calc(12);
        right: rem-calc(12);
        width: rem-calc(17);
        height: rem-calc(17);

        &.js-show {
            display: none;
        }
        &.js-hide {
            display: block;
        }

        .cc-account__viewPassword & {
            &.js-show {
                display: block;
            }
            &.js-hide {
                display: none;
            }
        }
    }

}

.cc-container-dis-picture {
    position: relative;

    img {
        max-width: 100%;
        position: relative;
        transition: transform .2s ease;
        z-index: 1;
        height: auto;
    }

    .cc-dis-loader-img {
        left: 0;
        position: absolute;
        top: 0;
        transform: scale(.1554);
    }
}

.cc-is-invalid-container {
    .invalid-feedback {
        display: block;
    }
}

.cc-valid-success {
    color: rgb(58, 183, 149);
    font-size: .925rem;
    font-weight: $primary-font-semibold;
    padding-top: rem-calc(7);

}

.cc-product-label {
    font-size: .875rem;
    font-style: italic;
    font-weight: $primary-font-semibold;
    position: absolute;
    right: .9375rem;
    text-transform: uppercase;
    top: .625rem;
    z-index: 2;

    .cc-dis-loader-img {
        display: none;
    }

    .cc-product-label__badge__style {
        display: inline-block;
    }

    .cc-label-discount {
        .cc-product-label__badge__style {
            color: $red1;
            font-size: rem-calc(12);
            line-height: rem-calc(13);
            letter-spacing: rem-calc(1.2);
            font-weight: 800;
            border-radius: rem-calc(2);
            background: rgba(230, 0, 0, 0.20);
            padding: rem-calc(4);
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline;
            width: fit-content;
            letter-spacing: 10%;
        }
    }

    //per frontendista: da controllare
    .cc-label-clubprice {
        color: $red1;

        &::before {
            font-size: rem-calc(65);
        }
    }

    //IN-AR TRY ON
    .cc-label-try-on-ar {

        display: block;
        text-align: right;

        img {
            width: 20%;
            height: auto;
            margin-right: rem-calc(16);
            margin-left: auto;
        }
    }
}

.cc-product {
    &-promo {
        font-size: rem-calc(13);
        line-height: rem-calc(18);
        font-weight: 800;
        color: $warm-green;
        margin: 0;
        display: inline-block;
        position: relative;

        .cc-content-minicart-items &,
        .cc-cart-product-main & {
            font-size: rem-calc(11);
            line-height: rem-calc(16);
        }

        .cc-info-icon {
            display: inline-block;
            width: rem-calc(16);
            height: rem-calc(16);
        }


        &--wrapper {
            display: inline-block;
            position: relative;
            cursor: pointer;

            .cc-cart-product-main & {
                margin-top: rem-calc(8);
            }

            + div.cc-attribute-stockLine {
                margin-top: 0;
            }
        }
    }

    &__tooltip {
        @include animation();
        z-index: -1;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        top: calc(100% + 10px);
        font-size: rem-calc(11);
        font-weight: $primary-font-regular;
        line-height: rem-calc(16);
        letter-spacing: rem-calc(0.32);
        padding: rem-calc(12) rem-calc(16);
        border-radius: rem-calc(2);
        background: $white;
        max-width: rem-calc(145);
        width: max-content;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.20));
        color: $black;

        @include media-breakpoint-down(md) {
            transform: unset;
            left: 0;
            max-width: calc(100vw - 32px);

            .cc-cart-product-main & {
                max-width: calc(100vw - 160px);
            }
        }

        &__wrapper {
            display: inline-block;

            @include media-breakpoint-up(md) {
                position: relative;
            }

            .cc-content-minicart-items & {
                display: none;
            }

            &__icon {
                display: inline-block;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: rem-calc(19);
                    z-index: 4;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: rem-calc(8) solid transparent;
                    border-right: rem-calc(8) solid transparent;
                    border-bottom: rem-calc(8) solid $white;
                    visibility: hidden;
                    opacity: 0;

                    .plp-promotions & {
                        top: rem-calc(-6);
                        transform: translateX(-50%) rotate(180deg);
                    }

                    .cc-product-promo:hover &,
                    .cc-product-promo--wrapper:hover & {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        .plp-promotions & {
            top: auto;
            bottom: rem-calc(23);

            @include media-breakpoint-down(md) {
                max-width: calc(50vw - 24px);
            }
        }

        .cc-product-promo:hover &,
        .cc-product-promo--wrapper:hover & {
            visibility: visible;
            opacity: 1;
            z-index: 2;
        }
    }
}

.modal-dialog {
    &.modal-dialog--otp {
        max-width: rem-calc(560);
    }
}

.cc-modal-default {

    .modal-dialog {
        margin: 0 auto;
        max-height: 95%;
        max-width: rem-calc(560);
        overflow-y: auto;
        top: 50%;
        transform: translateY(-50%) !important;

        @include media-breakpoint-down(xs) {
            max-width: calc(100% - 30px);
        }
    }

    .modal-content {
        border-radius: 0;
        padding: 0 rem-calc(30) rem-calc(40) rem-calc(30);

        @include media-breakpoint-down(xs) {
            padding: 0 rem-calc(20) rem-calc(40) rem-calc(20);
        }
    }

    .modal-header {
        border: 0;
        border-radius: 0;
        display: block;
        padding-top: rem-calc(50);

        @include media-breakpoint-down(xs) {
            margin: 0 auto;
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        .close {
            font-size: rem-calc(21);
            position: absolute;
            right: rem-calc(20);
            top: rem-calc(20);
            z-index: 1;
        }

        .modal-title {
            color: $black;
            font-size: rem-calc(20);
            font-weight: $primary-font-semibold;
            line-height: rem-calc(24);
            padding-bottom: rem-calc(13);
            position: relative;
            text-align: center;
            text-transform: uppercase;

            &::after {
                background: $red1;
                bottom: 0;
                content: '';
                display: block;
                height: rem-calc(1);
                left: 50%;
                margin-left: rem-calc(-20);
                position: absolute;
                width: rem-calc(40);
            }
        }
    }

    .modal-body {
        color: $black;
        font-size: rem-calc(14);
        font-weight: $primary-font-regular;
        line-height: rem-calc(21);
        padding: 0;
        text-align: center;
    }

    .modal-footer {
        border: 0;
        padding: rem-calc(20) 0 0;

        .cc-content-modal-buttons {
            text-align: center;
            width: 100%;

            button {
                margin-right: rem-calc(20);

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.modal {
    .modal-header {
        &.modal-header-phone {
            border: 0;
            height: 0;
        }
    }

    .cc-content-verification-elements {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        .cc-verification-content-form {
            flex: 0 0 65%;
            width: 65%;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }

        .cc-verification-content-button {
            flex: 0 0 35%;
            margin-top: rem-calc(24);
            padding-left: rem-calc(6);
            width: 35%;

            @include media-breakpoint-down(xs) {
                margin-top: 0;
                padding-left: 0;
                width: 100%;
            }
        }

        .cc-input {
            text-align: center;
        }

        .cc-label,
        .cc-input {
            @include media-breakpoint-down(xs) {
                text-align: center;
            }
        }
    }
}

.cc-svg-info {
    $svgColor: str-replace(''+ $primary-color, '#', '%23');
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 17.9 17.9' xml:space='preserve'%3E%3Crect x='8' y='8' fill='#{$svgColor}' width='1.8' height='5.4'/%3E%3Crect x='8' y='4.5' fill='#{$svgColor}' width='1.8' height='1.8'/%3E%3Cpath fill='#{$svgColor}' d='M8.9,0C4,0,0,4,0,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9C17.9,4,13.9,0,8.9,0z M8.9,16.1c-3.9,0-7.2-3.2-7.2-7.2 S5,1.8,8.9,1.8s7.2,3.2,7.2,7.2C16.1,12.9,12.9,16.1,8.9,16.1z'/%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% !important;
    display: inline-block;
    height: rem-calc(18);
    vertical-align: middle;
    width: rem-calc(18);
}

.cc-flex-break-row {
    flex-basis: 100%;
    height: 0;
}

.cc-flex-break-column {
    flex-basis: 100%;
    width: 0;
}

.iconClassCommon {
    @include animation();
    display: block;
    fill: currentColor;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up (lg) {
        &:hover {
            fill: $red;
        }
    }
}

.cc-featured-menu {
    margin-top: rem-calc(25);

    .cc-menu-item-3 {
         @include media-breakpoint-up (sm) {
            display: block;
        }
    }
}
.error-message-contact-us {
    display: none;
}
.cc-error-message-contact-us {
    align-items: center;
    background-color: $white;
    border: rem-calc(1) solid $red1;
    display: none;
    justify-content: center;
    margin: 0 rem-calc(20);
    margin-bottom: rem-calc(30);
    min-height: rem-calc(60);
    padding: rem-calc(20);
    width: calc(100% - 40px);

    @include media-breakpoint-up(sm) {
        margin-bottom: rem-calc(40);
    }

    .cc-error-message-text-contact-us {
        color: $red1;
        font-size: rem-calc(13);
        font-weight: $primary-font-semibold;
        text-align: center;
    }

}
.cc-error-message-redirect-contact-us {
    align-items: center;
    background-color: $white;
    border: rem-calc(1) solid $red1;
    display: flex;
    justify-content: center;
    margin: 0 rem-calc(20);
    margin-bottom: rem-calc(30);
    min-height: rem-calc(60);
    padding: rem-calc(20);
    width: calc(100% - 40px);

    @include media-breakpoint-up(sm) {
        margin-bottom: rem-calc(40);
    }

    .cc-error-message-text-contact-us {
        color: $red1;
        font-size: rem-calc(13);
        font-weight: $primary-font-semibold;
        text-align: center;
    }

}

.storepage {
    &:not(.cc-fullpage) {
        @include media-breakpoint-up (sm) {
            margin-top: rem-calc(60);
        }
    }
}

[data-action="Home-Show"] {
    .storepage {
        @include media-breakpoint-up (sm) {
            margin-top: 0;
        }
    }
}

.b-lazyload,
.b-lazyload_loading {
    &:not(.cc-no-placeholder) {
        background: #f4f4f4;
    }
}

@keyframes mouseoverButtonType1 {
    0% {
        background-color: $black-warm;
        border-color: $black-warm;
    }

    33% {
        background-color: #59000A;
        border-color: #59000A;
    }

    66% {
        background-color: $ruby-red;
        border-color: $ruby-red;
    }

    100% {
        background-color: $red;
        border-color: $red;
    }
}

// BATA Redesign

.cc-tile-product-brand {
    display: block;
    font-size: rem-calc(10);
    font-weight: $primary-font-extra-bold;
    line-height: rem-calc(14);
    letter-spacing: rem-calc(1);
    text-transform: uppercase;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(7);
    }
}

.cc-tile-product-name {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(8);
    }
}

.modal-backdrop.show {
    background: rgba(20, 0, 0, 0.20);
    backdrop-filter: blur(5px);
    opacity: 1;
}

.line-item-promo {
    color: $warm-green;
    margin-top: rem-calc(5);
    font-size: rem-calc(13);
    font-weight: $primary-font-semibold;
}

.hide-shipping-discount {
    display: none;
}

.hide-gst-benefit {
    display: none;
}

.cc-content-cart-error {

    &-visible {
        border-bottom: rem-calc(1) solid $warm-grey-02;
        padding-bottom: rem-calc(22);
        margin-bottom: rem-calc(18);
    }

    .valid-cart-error {
        font-size: rem-calc(13);
        font-weight: $primary-font-medium;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.13);
        margin-top: 0;
        padding: rem-calc(8) rem-calc(12);
        background: $bg-red;
        color: $red;

        button {
            display: none;
        }
    }
}

.cc-align-left {
    text-align: left;
}

#registration-send-otp {
    height: rem-calc(44);
    line-height: rem-calc(13);
}

.h-hidden {
    display: none !important;

    &-small {
        @include media-breakpoint-down (sm) {
            display: none !important;
        }
    }
}
