/* ==========================================================================

                        U I   K I T

========================================================================== */

.cc-uikit {
    padding: 30px 0;

    .cc-content-logo {

        .cc-logo {
            display: block;
            width: auto;
            margin: 0 auto;
            height: rem-calc(30);

            @include media-breakpoint-down(xs) {
                height: rem-calc(25);
            }
        }
    }

    .cc-ui-page-title {
        display: block;
        font-family: $primary-font;
        font-weight: $primary-font-medium;
        color: $black;
        font-size: rem-calc(20);
        text-align: center;
        text-transform: uppercase;
        padding-top: rem-calc(10);
    }

    .cc-uikit-section {
        margin-top: rem-calc(24);
        padding: rem-calc(24);

        .cc-ui-title {
            display: block;
            font-family: $primary-font;
            font-weight: $primary-font-medium;
            color: $black;
            font-size: rem-calc(25);
            text-align: left;
            text-transform: uppercase;
            padding-bottom: rem-calc(10);
            border-bottom: rem-calc(1) solid $black;
            margin-bottom: 40px;
        }

        .cc-ui-subtitle {
            display: block;
            font-family: $primary-font;
            font-weight: $primary-font-medium;
            color: $red1;
            font-size: rem-calc(25);
            text-align: left;
            text-transform: uppercase;
            padding-bottom: rem-calc(30);

            &.cc-ui-subtitle-second {
                padding-top: rem-calc(15);
            }
        }

        .cc-label-title {
            display: block;
            font-family: $primary-font;
            font-weight: $primary-font-semibold;
            color: $black-warm;
            font-size: rem-calc(14);
            text-align: left;
            text-transform: uppercase;
            padding-bottom: rem-calc(10);
        }

        .cc-content-palette-group {
            margin-bottom: rem-calc(20);

            .cc-content-palettes {
                text-align: left;
                font-size: 0;
            }

            .cc-palette {
                margin-right: rem-calc(60);
                display: inline-block;
                vertical-align: top;
                margin-bottom: rem-calc(15);

                .cc-palette-color {
                    display: block;
                    width: rem-calc(70);
                    height: rem-calc(70);
                    background: $white;
                    border: 1px solid $black-warm;
                    margin-bottom: rem-calc(10);

                    &.cc-palette-color-red1 {
                        background: $red1;
                        border-color: $red1;
                    }
                    &.cc-palette-color-black {
                        background: $black;
                        border-color: $black;
                    }
                    &.cc-palette-color-white {
                        background: $white;
                        border-color: $black-warm;
                    }
                    &.cc-palette-color-grey1 {
                        background: $black-warm;
                        border-color: $black-warm;
                    }
                    &.cc-palette-color-grey2 {
                        background: $grey2;
                        border-color: $grey2;
                    }
                    &.cc-palette-color-grey3 {
                        background: $grey3;
                        border-color: $grey3;
                    }
                    &.cc-palette-color-green1 {
                        background: $green1;
                        border-color: $green1;
                    }
                    &.cc-palette-color-pink1 {
                        background: $pink1;
                        border-color: $pink1;
                    }
                    &.cc-palette-color-yellow1 {
                        background: $yellow1;
                        border-color: $yellow1;
                    }
                    &.cc-palette-color-orange1 {
                        background: $orange1;
                        border-color: $orange1;
                    }
                    &.cc-palette-color-orange2 {
                        background: $orange2;
                        border-color: $orange2;
                    }
                }

                .cc-palette-hex {
                    display: block;
                    font-family: $primary-font;
                    font-weight: $primary-font-medium;
                    color: $black-warm;
                    font-size: rem-calc(12);
                    text-align: left;
                    text-transform: uppercase;
                }
            }
        }

        .cc-uikit-text {
            display: block;
            font-family: $primary-font;
            font-weight: $primary-font-medium;
            color: $black-warm;
            font-size: rem-calc(12);
            text-align: left;
            padding-bottom: rem-calc(10);
        }

        .cc-content-logo-versions {
            text-align: left;
            font-size: 0;

            .cc-logo-version {
                display: inline-block;
                vertical-align: top;
                margin-right: rem-calc(60);
                margin-bottom: rem-calc(15);

                .cc-logo-label {
                    display: block;
                    font-family: $primary-font;
                    font-weight: $primary-font-semibold;
                    color: $black-warm;
                    font-size: rem-calc(14);
                    text-align: left;
                    text-transform: uppercase;
                    padding-bottom: rem-calc(10);
                }

                .cc-content-logo {
                    width: auto;

                    .cc-logo {
                        display: block;
                        width: auto;
                        height: rem-calc(30);

                        @include media-breakpoint-down(xs) {
                            height: rem-calc(25);
                        }
                    }
                }

            }
        }

        .cc-uikit-category {
            display: block;
            font-weight: $primary-font-semibold;
            font-size: rem-calc(14);
            text-transform: uppercase;
            color: $black-warm;
            padding-bottom: rem-calc(8);
        }

        .cc-content-uikit-group {
            font-family: $primary-font;
            font-weight: $primary-font-regular;
            margin-bottom: rem-calc(30);

            &.cc-uikit-regular {
                font-weight: $primary-font-regular;
            }
            &.cc-uikit-italic {
                font-weight: $primary-font-regular;
                font-style: italic;
            }
            &.cc-uikit-medium {
                font-family: $secondary-font;
            }
            &.cc-uikit-medium-italic {
                font-weight: $primary-font-medium;
                font-style: italic;
            }
            &.cc-uikit-semibold {
                font-weight: $primary-font-semibold;
            }

            .cc-uikit-label {
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(12);
                color: $black-warm;
                padding-bottom: rem-calc(8);
            }
            
            .cc-uikit-main-text {
                display: block;
                font-size: rem-calc(20);;
                color: $black;
                padding-bottom: rem-calc(4);
            }

            .cc-uikit-example {
                display: block;
                font-size: rem-calc(20);
                color: $black;
                padding-bottom: rem-calc(5);
            }

            .cc-uikit-description {
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(12);
                color: $black-warm;
                padding-bottom: rem-calc(5);
            }

            .cc-content-navigation-group {
                text-align: left;
                font-size: 0;

                .cc-navigation-group {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 50px;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &--buttons {
            background-color: $cold-grey-03;
        }
    }
}
