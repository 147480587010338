/* ==========================================================================

                        B O O T S T R A P   O V E R R I D E S

========================================================================== */

.custom-control-input {
    display: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: unset;
    background-position: unset;
    background-repeat: unset;
    background-size: unset;
    border-color: unset;
    padding-right: unset;
}
