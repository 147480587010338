/* ==========================================================================

                        E R R O R   A V A I L A B I L I T Y

========================================================================== */
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
.cc-error-availability {
    display: block;
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);

    .cc-error-availability-main {
        position: absolute;
        background: $white;
        z-index: 2;
        width: 100%;
        max-width: rem-calc(700);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        overflow-y: auto;
        max-height: 95%;
        padding: 0 rem-calc(25) rem-calc(40) rem-calc(25);

        @include media-breakpoint-down(md) {
            width: calc(100% - 30px);
            padding: 0 rem-calc(15) rem-calc(20) rem-calc(15);
        }

        .cc-overlay-header {
            padding: rem-calc(65) rem-calc(45) rem-calc(20) rem-calc(45);

            @include media-breakpoint-down(xs) {
                padding: rem-calc(65) 0 rem-calc(10) 0;
            }

            .cc-overlay-title {
                text-align: center;
                font-weight: $primary-font-semibold;
                color: $black;
                font-size: rem-calc(20);
                line-height: rem-calc(24);
                text-transform: uppercase;
                position: relative;
                padding-bottom: rem-calc(13);
                margin: 0;
            }

            .cc-overlay-subtitle {
                text-align: center;
                font-weight: $primary-font-regular;
                color: $black;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                position: relative;
                padding-bottom: rem-calc(13);
                margin: 0;
            }

            .cc-close {
                position: absolute;
                z-index: 1;
                top: rem-calc(25);
                right: rem-calc(25);
                font-size: rem-calc(21);
            }
        }

        .cc-overlay-body {
            padding: 0 rem-calc(45);

            @include media-breakpoint-down(xs) {
                padding: 0;
            }

            .cc-suggested-section-title {
                display: block;
                font-weight: $primary-font-semibold;
                font-size: rem-calc(14);
                line-height: rem-calc(17);
                color: $black;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: rem-calc(10);

                &-second {
                    margin-top: rem-calc(40);
                }
            }

            .cc-content-radio {
                padding: rem-calc(11) 0 rem-calc(11) rem-calc(25);
                border: 1px solid $grey2;

                &.cc-active {
                    border-color: $black;
                }
            }

            .cc-cart-item {
                padding: rem-calc(25) 0;
                border-bottom: rem-calc(1) solid $grey2;

                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                }

                .cc-prod-name {
                    font-size: rem-calc(13);
                    font-weight: $primary-font-semibold;
                    margin-bottom: rem-calc(1);
                    display: block;
                    text-transform: uppercase;

                    &.cc-prod-name-mobile {
                        margin-bottom: rem-calc(10);
                    }
                }

                .cc-prod-row {
                    flex-wrap: nowrap;

                    @include media-breakpoint-down(xs) {
                        flex-wrap: wrap;
                    }

                    .cc-content-image {
                        padding-right: rem-calc(40);
                        width: 165px;
                        max-width: 165px;
                        flex: 0 0 165px;

                        @include media-breakpoint-down(xs) {
                            padding-right: rem-calc(10);
                            width: 80px;
                            max-width: 80px;
                            flex: 0 0 80px;
                        }

                        .cc-image, img {
                            width: 100%;
                        }
                    }

                    .cc-content-attributes {
                        font-size: rem-calc(13);

                        @include media-breakpoint-down(xs) {
                            width: calc(100% - 80px);
                            max-width: calc(100% - 80px);
                            flex: 0 0 calc(100% - 80px);
                        }

                        .cc-attribute .cc-attribute-value {
                            font-weight: $primary-font-semibold;
                            margin-left: rem-calc(2);
                        }

                        .cc-prod-card-price {
                            font-size: rem-calc(13);
                            margin-top: rem-calc(10);

                            @include media-breakpoint-up(sm) {
                                margin-top: rem-calc(15);
                            }

                            div:last-child {
                                font-weight: $primary-font-semibold;
                                font-style: italic;
                                margin-top: rem-calc(3);
                            }
                        }
                    }

                    .cc-content-quantity {
                        width: rem-calc(100);
                        align-self: center;

                        @include media-breakpoint-down(xs) {
                            width: calc(50% + 40px);
                            max-width: calc(50% + 40px);
                            flex: 0 0 calc(50% + 40px);
                            padding-top: rem-calc(10);
                            padding-left: rem-calc(80);
                            text-align: left;
                        }

                        .cc-attribute {
                            font-size: rem-calc(13);

                            .cc-attribute-value {
                                font-weight: $primary-font-semibold;
                            }
                        }
                    }
                    .cc-price-item {
                        width: rem-calc(110);
                        align-self: center;
                        text-align: right;

                        @include media-breakpoint-down(xs) {
                            width: calc(50% - 40px);
                            max-width: calc(50% - 40px);
                            flex: 0 0 calc(50% - 40px);
                            padding-top: rem-calc(10);
                        }

                        .cc-price {
                            font-weight: $primary-font-semibold;
                            font-size: rem-calc(13);
                            font-style: italic;
                            text-align: right;
                        }
                    }
                }
            }

        }
        .cc-content-button {
            padding: 0 0 rem-calc(10) 0;
            text-align: center;
            margin: 0;

            @include media-breakpoint-down(xs) {
                padding-top: rem-calc(10);
            }

            .cc-button-type-1 {
                //max-width: rem-calc(350);
                margin: 0 auto;
            }
        }
    }

}
