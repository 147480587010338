/* ==========================================================================

                        C O O K I E

========================================================================== */

.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.7);
    padding: rem-calc(24) 0;

    @include media-breakpoint-down(xs) {
        padding: rem-calc(20) 0;
    }

    .cc-content-cookie-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: rem-calc(1130);
        margin: 0 auto;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }
    }

    .cc-cookie-text {
        display: block;
        font-weight: $primary-font-medium;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $white;
        text-align: left;
        margin: 0;
        padding: 0;
        width: calc(100% - 160px);
        max-width: calc(100% - 160px);
        flex: 0 0 calc(100% - 160px);

        @include media-breakpoint-down(xs) {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            padding-bottom: rem-calc(15);
            text-align: center;
            font-size: rem-calc(13);
        }
    }

    .cc-button-type-2 {
        margin-left: auto;

        @include media-breakpoint-down(xs) {
            margin: 0 auto;
        }
    }
}
