.cc-cart-promotion-bars__container,
.cc-minicart-promotion-bars__container {
    border-bottom: rem-calc(1) solid $warm-grey-02;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    column-gap: rem-calc(24);
    row-gap: rem-calc(16);

    .cc-promotion-progress-bar__container {
        flex-basis: 100%;

        .cc-promotion-progress-bar__label-container {
            display: flex;
            flex-direction: row;
            column-gap: rem-calc(10);
            align-items: center;
            margin-bottom: rem-calc(8);

            .cc-promotion-achieved-icon {
                display: none;
                width: rem-calc(20);
                height: rem-calc(20);
            }
    
            .cc-promotion-progress-bar__label {
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                font-weight: $primary-font-bold;
                color: $warm-grey-05;
            }
        }
    
        .cc-promotion-progress-bar__outer-bar {
            display: flex;
            height: rem-calc(6);
            background-color: $warm-grey-01;
            border-radius: rem-calc(6);
    
            .cc-promotion-progress-bar__inner-bar {
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: rem-calc(6);
    
                background-color: $warm-grey-05;
    
                transition: width 0.6s ease;
            }
        }

        &.cc-promotion-progress-bar__full {

            .cc-promotion-achieved-icon {
                display: block;
            }

            .cc-promotion-progress-bar__label {
                color: $green1;
            }

            .cc-promotion-progress-bar__inner-bar {
                background-color: $green1;
            }
        }
    }
}

.cc-cart-promotion-bars__container {
    padding-bottom: rem-calc(24);
    width: 100%;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    .cc-promotion-progress-bar__container {
        margin-bottom: rem-calc(8);

        @include media-breakpoint-up(lg) {
            flex: 1 1 calc(50% - 0.75rem);

            &:nth-last-child(1):nth-child(odd) {
                flex-basis: 100%;
            }
        }
    }
}

.cc-minicart-promotion-bars__container {
    padding: rem-calc(16) 0 rem-calc(12) 0;
    margin: 0 rem-calc(16) rem-calc(12) rem-calc(16);

    .cc-promotion-progress-bar__container {
        margin-right: rem-calc(28);
        margin-bottom: rem-calc(6);
    }
}
