/* ==========================================================================

                        S L I C K   S L I D E R

========================================================================== */

body {

    .slick-slider {

        .slick-slide {
            height: auto;
        }
    }
}
